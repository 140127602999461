import CustomTooltip from "../Tooltip/Tooltip";

import InfoIcon from "../../assets/svg/infoIcon.svg";

const TextArea = ({
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  row,
  required,
  outerClass,
  info,
  ...rest
}) => {
  return (
    <div className={`${outerClass}`}>
      <div
        className={`flex items-center py-0 gap-1 font-semibold text-sm ${labelClass}`}
      >
        <span>
          {label}
          {required ? " *" : ""}
        </span>
        {info && (
          <CustomTooltip title={info} placement="right">
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        )}
      </div>
      <div
        className={`flex rounded-[0.8rem] items-center shadow-inputShadow hover:shadow-inputShadowActive ${extraClass}`}
      >
        <textarea
          className={`resize-none text-secondary-gray bg-transparent focus:outline-none w-full py-[.4rem] text-sm overflow-auto ${inputFieldClass}`}
          type={type}
          placeholder={placeholder}
          name={name}
          rows={row}
          id={id}
          {...rest}
        />
        {Icon && <Icon onClick={handleIconClick} className={iconClass} />}
      </div>

      {error && (
        <p className="mt-1.5 pl-1 font-normal text-xs lg:text-[.9rem] 5xl:text-base 6xl:text-base text-primary-red">
          {error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
