import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import CustomTooltip from "../Tooltip/Tooltip";
import { ToggleSidebarContext } from "../Layout/Layout";
import Input from "../Input/Input";
import Header from "../Header/Header";
import Button from "../Button/Button";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";

import DeleteIcon from "../../assets/svg/deleteIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";

export default function AddReferenceTopic() {
  const { id, qid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const quickRefData = location.state?.quickRefData;

  const [community, setCommunity] = useState({});
  const [quickReference, setQuickReference] = useState({
    topic: "",
    details: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setQuickReference((prev) => ({ ...prev, [name]: value }));
  };

  const addQuickReference = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/community/quickReference", {
        communityId: id,
        topic: quickReference.topic,
        details: quickReference.details,
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/document-library`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const editQuickReference = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.patch("/api/community/quickReference", {
        quickReferenceId: qid,
        communityId: id,
        updateFields: quickReference,
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/document-library`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteQuickReference = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/community/quickReference?quickReferenceId=${qid}`
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/document-library`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (qid)
      setQuickReference({
        topic: quickRefData?.topic,
        details: quickRefData?.details,
      });
  }, [qid]);

  return (
    <div className="w-full">
      <Header
        title={qid ? "Edit Quick Reference Topic" : "Add Quick Reference Topic"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col pt-4 px-8 gap-4"
      >
        <div className="flex flex-col gap-[1rem] grow">
          <Input
            label="Topic"
            placeholder="Enter New Topic"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-4"
            required={true}
            outerClass="w-full "
            extraClass="rounded-full"
            info={"info"}
            value={quickReference.topic}
            onChange={onChangeHandler}
            name="topic"
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-[6px] text-primary-teal font-semibold text-[0.9rem]">
              Details
              <CustomTooltip title={"info"} placement="right">
                <img src={InfoIcon} alt="" className="cursor-pointer" />
              </CustomTooltip>
            </div>
            <textarea
              placeholder="Add Details"
              style={{
                height: "15rem",
                borderRadius: "12px",
                backgroundColor: "#fff",
                outline: "1px solid #f6f6f6",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                color: "#666666",
                padding: "1rem",
                fontSize: "0.8rem",
              }}
              value={quickReference.details}
              onChange={onChangeHandler}
              name="details"
            />
          </div>
        </div>

        <div className=" flex gap-4 justify-between">
          <div>
            {qid && (
              <Button
                type="submit"
                Icon={DeleteIcon}
                iconClass={"w-[15px] h-[15px]"}
                innerText="Delete"
                extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full text-[0.8rem] uppercase text-primary-red px-4 py-2"
                onClick={deleteQuickReference}
                isLoading={isLoadingDelete}
              />
            )}
          </div>
          <div className="flex gap-[1rem]">
            <Button
              type="submit"
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
              onClick={() => navigate(`/community/${id}/document-library`)}
            />
            {qid ? (
              <Button
                type="submit"
                innerText="Save Changes"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
                onClick={editQuickReference}
                isLoading={isLoading}
                disabled={
                  isLoading || !quickReference.topic || !quickReference.details
                }
                spinnerLight={true}
              />
            ) : (
              <Button
                type="submit"
                innerText="Add Topic"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
                isLoading={isLoading}
                disabled={
                  isLoading || !quickReference.topic || !quickReference.details
                }
                spinnerLight={true}
                onClick={addQuickReference}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
