import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ClipLoader } from "react-spinners";

import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import Button from "../Button/Button";
import CKEditorComponent from "../CKEditor/CKEditor";
import { api } from "../../utils/helpers/url";
import Select from "../Input/SelectInput";
import MarginModel from "../MarginModel/MarginModel";
import CustomTooltip from "../Tooltip/Tooltip";
import { setCommunityId } from "../../redux/features/communitySlice";
import { ToggleSidebarContext } from "../Layout/Layout";
import BasicTooltip from "../Tooltip/EditorTooltip";
import { convertFileImageIntoBase64 } from "../../utils/helpers/filetoBase64";

import ImportIcon from "../../assets/svg/importIcon";
import MarginIcon from "../../assets/svg/marginIcon.svg";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import UploadFromDekstopIcon from "../../assets/svg/uploadFromDekstopIcon.svg";

export default function AddLetterTemplateCompany() {
  const { id, vid } = useParams();
  const auth = useSelector((store) => store.auth);
  const previewBtnRef = useRef("");
  const imageUploadRef = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [value, setValue] = useState("");
  const [letterTemplate, setLetterTemplate] = useState({
    title: "",
    level: "",
    levelId: "",
  });
  const [margins, setMargins] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [letterTags, setLetterTags] = useState([]);
  const [tagSearch, setTagSearch] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [community, setCommunity] = useState({});
  const [letterLevels, setLetterLevels] = useState([]);
  const [showMarginModel, setShowMarginModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddLetter, setIsLoadingAddLetter] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;

    setLetterTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMargins({
      ...margins,
      [name]: Number(value),
    });
  };

  async function handleChange(e) {
    if (e.target.files[0]) {
      const data = await convertFileImageIntoBase64(e.target.files[0]);
      setFilePreview(data);
    }
  }

  const onSelectTag = (data) => {
    navigator.clipboard.writeText(data.tag);

    toast.success(`${data.name} copied to clipboard`);
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterTemplateId = async () => {
    try {
      const { data } = await axios.get(
        `/api/company/letterTemplate/community?communityId=${id}&pageNum=1&rowsPerPage=30&sortFields=updatedAt&filterFields=_id&filterValues=${vid}&sortOrders=-1`
      );

      if (data.success) {
        const letter = data.letterTemplates[0];

        setLetterTemplate({
          title: letter.title,
          levelId: letter.level,
          level: letterLevels.find((level) => level._id === letter.level)
            ?.title,
          margins: letter.margins,
        });
        setMargins(letter.margins);
        setValue(letter.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterTags = async () => {
    try {
      const { data } = await axios.get("/api/letter/tags");

      if (data.success) {
        setLetterTags(data.letterTags);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterLevels = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/letterLevel?communityId=${id}&archieved=no`
      );

      if (data.success) {
        setLetterLevels(data.letterLevels);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addLetterTemplate = async () => {
    try {
      setIsLoadingAddLetter(true);

      const payload = {
        communityId: id,
        letterTemplates: [
          {
            title: letterTemplate.title,
            content: value,
            margins,
          },
        ],
      };

      if (letterTemplate.level)
        payload.letterTemplates[0].level = letterTemplate.levelId;

      const { data } = await axios.post(
        "/api/community/letterTemplate",
        payload
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/letter-library/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingAddLetter(false);
    }
  };

  const editLetterTemplate = async () => {
    try {
      setIsLoading(true);

      const payload = {
        communityId: id,
        letterTemplateId: vid,
        updateFields: {
          title: letterTemplate.title,
          content: value,
          margins,
        },
      };

      if (letterTemplate.level)
        payload.updateFields.level = letterTemplate.levelId;

      const { data } = await axios.patch(
        "/api/community/letterTemplate",
        payload
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/letter-library/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLetterTags();
  }, []);

  useEffect(() => {
    if (vid) getLetterTemplateId();
  }, [vid, letterLevels]);

  useEffect(() => {
    if (id) {
      getCommunity();
      getLetterLevels();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <input
        type="file"
        className="hidden"
        ref={imageUploadRef}
        onChange={handleChange}
      />
      <Header
        title={vid ? "Edit Letter Template" : "Letter Template"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />

      <form
        style={{ display: "none" }}
        method="post"
        target="_blank"
        action={`${api}/api/letter/letterPreview?token=${auth.token}`}
      >
        <input type="hidden" name="letterData" value={value} />
        <input type="hidden" name="marginTop" value={margins.top} />
        <input type="hidden" name="marginBottom" value={margins.bottom} />
        <input type="hidden" name="marginLeft" value={margins.left} />
        <input type="hidden" name="marginRight" value={margins.right} />
        <input type="submit" ref={previewBtnRef} />
      </form>

      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4 "
      >
        {!vid && (
          <div className="flex items-center gap-4">
            <Button
              type="submit"
              Icon={ImportIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-[0.75rem] text-primary-white w-fit"
              innerText="Import from the Master Letter Library"
              onClick={() => {
                navigate(
                  `/community/letter-library/${id}?importFromCompanyAdmin=true`
                );
              }}
            />
            <span className="text-primary-gray-light text-[0.9rem]">
              Create a new letter or save some time by selecting from Master
              Letter Library. All letters are editable after import.
            </span>
          </div>
        )}
        <div className="flex flex-col gap-[2rem] grow">
          <div className="flex gap-4">
            <Input
              label="Template Name"
              placeholder="Enter Template Name"
              type="text"
              labelClass="text-primary-teal"
              inputFieldClass="px-4"
              required={true}
              outerClass="w-full"
              extraClass="border-primary-gray border-[1px] rounded-full"
              onChange={(e) => {
                onChange(e);
              }}
              value={letterTemplate.title}
              name="title"
            />

            <Select
              label="Level"
              labelClass="text-primary-teal"
              type="select"
              placeholder="Select Level"
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-primary-gray border-[1px] rounded-full"
              dropdownData={letterLevels}
              value={letterTemplate.level}
              handleChange={(id, data) => {
                setLetterTemplate((prev) => ({
                  ...prev,
                  level: data.title,
                  levelId: data._id,
                }));
              }}
              fieldName="title"
              info={"info"}
            />

            <div className="w-full">
              <div className="flex gap-1">
                <span className={"text-primary-teal text-sm font-semibold"}>
                  Tags
                </span>
                <CustomTooltip
                  title={`Tags will automatically tell the system to pull the data associated with the tag and place it in the letter.
To use: 
1) Place your cursor in the desired area of the template. 
2) Select the tag you wish to insert from the dropdown. Keep and eye out for the message at the bottom of the screen which says that the tag has been copied.
3) Either right-click on your mouse and  select "paste" or press Ctrl + V on your keyboard.
4) Tag will then be inserted. Be sure not to modify the tag in any way.`}
                  placement={"right"}
                >
                  <img src={InfoIcon} alt="" className="cursor-pointer" />
                </CustomTooltip>
              </div>
              <Autocomplete
                onChange={(e, newValue) => {
                  if (!newValue) return;

                  const LetterTagSelected = letterTags.find(
                    (letterTag) => letterTag.name === newValue
                  );
                  onSelectTag(LetterTagSelected);
                }}
                inputValue={tagSearch}
                onInputChange={(e, newInputValue) => {
                  setTagSearch(newInputValue);
                }}
                options={letterTags.map((lettertag) => lettertag.name)}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField placeholder="Select tags" {...params} />
                )}
                size="small"
              />
            </div>
          </div>
          <div className="relative">
            <div
              className="absolute right-0 p-[0.3rem]"
              style={{ zIndex: "100" }}
            >
              <div className="flex items-center">
                <BasicTooltip
                  Icon={
                    <img
                      src={UploadFromDekstopIcon}
                      alt=""
                      onClick={() => {
                        imageUploadRef.current.click();
                      }}
                      className={`"cursor-pointer"`}
                    />
                  }
                  title="Upload image from computer"
                />
                <BasicTooltip
                  Icon={
                    <img
                      src={MarginIcon}
                      alt=""
                      onClick={() => setShowMarginModel(!showMarginModel)}
                      className="cursor-pointer"
                    />
                  }
                  title="Adjust Margin"
                />
              </div>

              {showMarginModel && (
                <MarginModel
                  margins={margins}
                  onChange={handleInputChange}
                  setShowMarginModel={setShowMarginModel}
                  setMargins={setMargins}
                  letterTemplate={letterTemplate}
                  edit={vid}
                />
              )}
            </div>
            <CKEditorComponent
              setValue={setValue}
              value={value}
              filePreview={filePreview}
            />
          </div>
        </div>
        <div className="flex justify-between pb-4">
          <div className="flex gap-x-4 ">
            <Button
              innerText={`Preview`}
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-sm px-4 text-primary-white"
              onClick={() => {
                previewBtnRef.current.click();
              }}
              disabled={isLoading}
            />
            <Button
              type="submit"
              innerText="Cancel"
              extraClass="shadow-button bg-primary-whitepx-4 border-[1px] border-primary-red rounded-full py-2 px-4 text-sm captilize text-primary-red"
              onClick={() => navigate(`/community/letter-library/${id}`)}
            />
          </div>
          {vid ? (
            <Button
              type="submit"
              innerText="Save Changes"
              extraClass="shadow-button bg-primary-teal px-4 border-[1px] border-primary-teal rounded-full py-2 text-sm xl:text-sm captilize text-primary-white"
              onClick={editLetterTemplate}
              isLoading={isLoading}
              spinnerLight={true}
            />
          ) : (
            <Button
              type="submit"
              innerText="Save Template"
              extraClass="shadow-button bg-primary-teal px-4 border-[1px] border-primary-teal rounded-full py-2 text-sm captilize text-primary-white"
              onClick={addLetterTemplate}
              isLoading={isLoadingAddLetter}
              spinnerLight={true}
              disabled={isLoadingAddLetter}
            />
          )}
        </div>
      </div>
    </div>
  );
}
