import { useContext } from "react";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import DatePickerInput from "../../components/Input/DatepickerInput";
import Select from "../../components/Input/SelectInput";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

import ImportIcon from "../../assets/svg/importIconWhite";

export default function Reports() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="w-full">
      <Header title="Reports" buttonText="Northdale HOA .Inc" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex flex-col grow gap-[1rem]">
          <div className="flex justify-between gap-[1rem] w-full">
            <Select
              label="Report"
              labelClass="text-primary-teal"
              type="select"
              placeholder="Select Report"
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px] rounded-full"
              id="subscriptionType"
              fieldName="subscriptionType"
              info={"info"}
              handleChange={() => {}}
              value={""}
              dropdownData={[]}
            />
            <Select
              label="Community"
              labelClass="text-primary-teal"
              type="select"
              placeholder="Select Community"
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px] rounded-full"
              id="subscriptionType"
              fieldName="subscriptionType"
              info={"info"}
              handleChange={() => {}}
              value={""}
              dropdownData={[]}
            />
          </div>
          <div className="flex justify-between gap-[1rem] w-full">
            <DatePickerInput
              label="Start Date"
              labelClass="text-primary-teal"
              placeholder="Start Date"
              type="text"
              inputFieldClass="px-4"
              id="subscriptionStartDate"
              outerClass="w-full"
              extraClass="border-gray border-[1px] rounded-full"
              info={"info"}
            />
            <DatePickerInput
              label="End Date"
              labelClass="text-primary-teal"
              placeholder="End Date"
              type="text"
              inputFieldClass="px-4"
              id="subscriptionEndDate"
              outerClass="w-full"
              extraClass="border-gray border-[1px] rounded-full"
              info={"info"}
            />
            <Select
              label="Level"
              labelClass="text-primary-teal"
              type="select"
              placeholder="Select Level"
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px] rounded-full"
              id="subscriptionType"
              fieldName="subscriptionType"
              info={"info"}
              handleChange={() => {}}
              value={""}
              dropdownData={[]}
            />
          </div>
          <div className="flex flex-col gap-[1rem]">
            <div className="w-1/3 flex justify-between border-b-[1px] border-gray">
              <span className="text-primary-teal font-semibold">
                Initial violation Date Range
              </span>
              <SwitchInputv2 isChecked={true} onChange={(id, value) => {}} />
            </div>
            <div className="flex justify-between gap-[1rem] w-[66%]">
              <DatePickerInput
                label="Start Date"
                labelClass="text-primary-teal"
                placeholder="Start Date"
                type="text"
                inputFieldClass="px-4"
                id="subscriptionStartDate"
                outerClass="w-full"
                extraClass="border-gray border-[1px] rounded-full"
                info={"info"}
              />
              <DatePickerInput
                label="End Date"
                labelClass="text-primary-teal"
                placeholder="End Date"
                type="text"
                inputFieldClass="px-4"
                id="subscriptionEndDate"
                outerClass="w-full"
                extraClass="border-gray border-[1px] rounded-full"
                info={"info"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[1rem]">
            <div className="w-1/3 flex justify-between border-b-[1px] border-gray">
              <span className="text-primary-teal font-semibold">
                Closed violation Date Range
              </span>
              <SwitchInputv2 isChecked={true} onChange={(id, value) => {}} />
            </div>
            <div className="flex justify-between gap-[1rem] w-[66%]">
              <DatePickerInput
                label="Start Date"
                labelClass="text-primary-teal"
                placeholder="Start Date"
                type="text"
                inputFieldClass="px-4"
                id="subscriptionStartDate"
                outerClass="w-full"
                extraClass="border-gray border-[1px] rounded-full"
                info={"info"}
              />
              <DatePickerInput
                label="End Date"
                labelClass="text-primary-teal"
                placeholder="End Date"
                type="text"
                inputFieldClass="px-4"
                id="subscriptionEndDate"
                outerClass="w-full"
                extraClass="border-gray border-[1px] rounded-full"
                info={"info"}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            type="submit"
            Icon={ImportIcon}
            iconClass="w-[15px] h-[15px]"
            innerText="Download PDF"
            extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal px-4 text-sm py-2 rounded-full text-primary-white"
          />
          <Button
            type="submit"
            Icon={ImportIcon}
            iconClass="h-[1rem] "
            innerText="Download Excel"
            extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal px-4 text-sm py-2 rounded-full text-primary-white"
          />
        </div>
      </div>
    </div>
  );
}
