import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "react-responsive-modal";
import { useDispatch } from "react-redux";

import Header from "../../components/Header/Header";
import LabelValueField from "../../components/LabelValueField/LabelValueField";
import Button from "../../components/Button/Button";
import axios from "../../utils/helpers/axios";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import UserIcon from "../../assets/svg/userIcon";
import EditIcon from "../../assets/svg/editIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function PropertyDetails() {
  const { id, pid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [property, setProperty] = useState({});
  const [warnNotes, setWarnNotes] = useState([]);
  const [openViolations, setOpenViolations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingViolation, setIsLoadingViolation] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const getMailingAddress = () => {
    const ownerData = property.propertyOwnerShip?.[0];
    const alternateAddressData = ownerData?.alternateAddress;

    if (ownerData?.isMailingAddressDifferent) {
      const mailingData = [
        alternateAddressData?.firstOwnerFirstName,
        alternateAddressData?.firstOwnerLastName,
        alternateAddressData?.streetNumber,
        alternateAddressData?.streetName,
        alternateAddressData?.city,
        alternateAddressData?.state,
        alternateAddressData?.zip,
      ];

      const check = mailingData.every((item) => item);

      if (check)
        return `${alternateAddressData.firstOwnerFirstName} ${
          alternateAddressData.firstOwnerLastName
        }  ${
          alternateAddressData.secondOwnerFirstName
            ? `& ${alternateAddressData.secondOwnerFirstName} ${alternateAddressData.secondOwnerLastName}`
            : ""
        } ${
          ", " +
          alternateAddressData.streetNumber +
          " " +
          alternateAddressData.streetName
        }
      ${", " + alternateAddressData.city}
      ${", " + alternateAddressData.state}
      ${", " + alternateAddressData.zip}`;
    } else {
      const mailingData = [
        ownerData?.firstOwnerFirstName,
        ownerData?.firstOwnerLastName,
        property?.streetNumber,
        property?.streetName,
        property?.city,
        property?.state,
        property?.zip,
      ];
      const check = mailingData.every((item) => item);

      if (check)
        return `${ownerData.firstOwnerFirstName} ${
          ownerData.firstOwnerLastName
        } ${
          ownerData.secondOwnerFirstName
            ? `& ${ownerData.secondOwnerFirstName} ${ownerData.secondOwnerLastName}`
            : ""
        }  ${", " + property.streetNumber + " " + property.streetName}
      ${", " + property.city}
      ${", " + property.state}
      ${", " + property.zip} `;
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPropertyWarnNote = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/warnNote?propertyId=${pid}&pageNum=1&rowsPerPage=2&sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) {
        setWarnNotes(data.warnNotes);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOpenViolations = async () => {
    try {
      setIsLoadingViolation(true);

      const { data } = await axios.get(
        `/api/job/openViolations?propertyId=${pid}`
      );

      if (data.success) {
        const violations = data.violations.slice(0, 3);

        setOpenViolations(violations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingViolation(false);
    }
  };

  const deleteproperty = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/delete?propertyId=${pid}`
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/properties`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const generateAccessToken = async () => {
    try {
      setIsLoadingToken(true);

      const { data } = await axios.post(
        "api/property/generatePropertyAccessToken",
        {
          propertyId: pid,
        }
      );

      if (data.success) {
        toast.success(data.message);
        getPropertyDetails();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (pid && id) getPropertyDetails();
  }, [id, pid]);

  useEffect(() => {
    if (pid) {
      getPropertyWarnNote();
      getOpenViolations();
    }
  }, [pid]);

  return (
    <div className="w-full">
      <Header
        title="Property Details"
        buttonText={community?.legalName}
        address={
          isLoading
            ? " "
            : `${property.streetNumber} ${property.streetName},
            ${property.addressLine2 ? property.addressLine2 + "," : ""}
             ${property.city}, ${property.state}`
        }
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      {isLoading && (
        <div className="flex flex-1 justify-center p-[4rem]">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      {!isLoading && (
        <div
          onClick={() => setToggleSidebar(false)}
          className="w-full h-[92%] flex flex-col px-10 pt-4 gap-4"
        >
          <div className="flex gap-[2rem] grow w-full">
            <div className="flex flex-col w-1/2">
              <div>
                <div className="flex justify-between text-primary-teal border-b-[1px] border-primary-gray-light">
                  <div className="flex items-center">
                    <PropertiesIcon />
                    <span className="ml-1">Property Address</span>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        `/community/${id}/property/${pid}/property-details/edit?editPropertyDetails=true`
                      )
                    }
                    className="flex items-center cursor-pointer text-secondary-gray"
                  >
                    <EditIcon />
                    <span className="ml-1">Edit</span>
                  </div>
                </div>
                <div className="flex flex-col gap-[0.4rem] p-[0.5rem]">
                  <LabelValueField
                    label="Street No."
                    value={property.streetNumber}
                    isEditable={true}
                  />
                  <LabelValueField
                    label="Street Name"
                    value={property.streetName}
                    isEditable={true}
                  />
                  <LabelValueField
                    label="Address Line 2"
                    value={property.addressLine2}
                    isEditable={true}
                  />

                  <div className="flex justify-between">
                    <LabelValueField
                      label="City"
                      value={property.city}
                      isEditable={true}
                    />
                    <LabelValueField
                      label="State"
                      value={property.state}
                      isEditable={true}
                    />
                    <LabelValueField
                      label="Zip"
                      value={property.zip}
                      isEditable={true}
                    />
                  </div>
                  <LabelValueField
                    label="Tenent Occupied"
                    value={property.tenantOccupied}
                    isEditable={true}
                  />
                </div>
              </div>
              <div>
                <div className="flex justify-between text-primary-teal border-b-[1px] border-primary-gray-light pt-[1rem]">
                  <div className="flex items-center">
                    <UserIcon />
                    <span className="ml-1">Owner's Details</span>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        `/community/${id}/property/${pid}/owner-details/${property.propertyOwnerShip?.[0]._id}/edit`
                      )
                    }
                    className="flex items-center cursor-pointer text-secondary-gray"
                  >
                    <EditIcon />
                    <span className="ml-1">Edit</span>
                  </div>
                </div>
                <div className="flex flex-col gap-[0.4rem] p-[0.5rem]">
                  <div className="flex justify-between">
                    <LabelValueField
                      extraClass="w-1/2"
                      label="Owner's Token ID"
                      value={property.ownershipAccessCode}
                      isEditable={true}
                    />
                    <Button
                      innerText={`Generate New Token ID`}
                      extraClass={`shadow-button bg-primary-white border-[1px] border-gray  rounded-full text-[0.8rem] text-primary-teal px-4 py-2`}
                      onClick={generateAccessToken}
                      isLoading={isLoadingToken}
                      disabled={isLoadingToken}
                    />
                  </div>
                  <div className="flex gap-[1rem]">
                    <div className="w-1/2">
                      <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                        <span>First Owner</span>
                      </div>
                      <div className="flex flex-col gap-[0.3rem] py-[0.5rem]">
                        <LabelValueField
                          label="First Name"
                          value={
                            property.propertyOwnerShip?.[0].firstOwnerFirstName
                          }
                          isEditable={true}
                        />
                        <LabelValueField
                          label="Last Name"
                          value={
                            property.propertyOwnerShip?.[0].firstOwnerLastName
                          }
                          isEditable={true}
                        />
                      </div>
                    </div>
                    <div className="w-1/2 ">
                      <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                        <span>Second Owner</span>
                      </div>
                      <div className="flex flex-col gap-[0.3rem] py-[0.5rem]">
                        <LabelValueField
                          label="First Name"
                          value={
                            property.propertyOwnerShip?.[0].secondOwnerFirstName
                          }
                          isEditable={true}
                        />
                        <LabelValueField
                          label="Last Name"
                          value={
                            property.propertyOwnerShip?.[0].secondOwnerLastName
                          }
                          isEditable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                      <span>Details</span>
                    </div>
                    <div className="flex flex-col gap-[0.3rem] py-[0.5rem]">
                      <LabelValueField
                        label="Mobile No."
                        value={property.propertyOwnerShip?.[0].mobileNumber}
                        isEditable={true}
                      />
                      <LabelValueField
                        label="Email"
                        value={property.propertyOwnerShip?.[0].email}
                        isEditable={true}
                      />
                      <LabelValueField
                        label="Mailing Address"
                        value={getMailingAddress()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div>
                <div className="flex text-primary-teal border-b-[1px] border-primary-gray-light">
                  <div className="flex items-center">
                    <ViolationIcon />
                    <span className="ml-1">Current Open Violations</span>
                  </div>
                </div>
                <div className="flex flex-col gap-[0.6rem] py-[0.6rem]">
                  {isLoadingViolation && (
                    <div className="flex flex-1 justify-center">
                      <ClipLoader color={"#0080A2"} size={50} />
                    </div>
                  )}
                  {!isLoadingViolation &&
                    openViolations.map((violation) => {
                      return (
                        <div className="flex flex-col gap-[0.4rem] border-b-[1px] border-primary-gray-light pb-[0.5rem]">
                          <div className="text-primary-black">
                            {violation.violationData.content}
                          </div>
                          <div className="text-primary-gray-light text-sm">
                            <span>{formatTimestamp(violation.openedOn)}</span>
                          </div>
                        </div>
                      );
                    })}

                  <div
                    onClick={() =>
                      navigate(`/community/${id}/property/${pid}/openViolation`)
                    }
                    className="flex justify-center py-[0.4rem] cursor-pointer text-primary-black"
                  >
                    View All
                  </div>
                  <div className="flex justify-between">
                    <Button
                      type="submit"
                      innerText="Photo History"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-10 py-2"
                      onClick={() =>
                        navigate(
                          `/community/${id}/property/${pid}/photo-history`
                        )
                      }
                    />
                    <Button
                      type="submit"
                      innerText="Violation History"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-10 py-2"
                      onClick={() =>
                        navigate(
                          `/community/${id}/property/${pid}/violation-history`
                        )
                      }
                    />
                    <Button
                      type="submit"
                      innerText="Letter History"
                      isLoading={isLoading}
                      disabled={isLoading}
                      extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-10 py-2"
                      onClick={() =>
                        navigate(
                          `/community/${id}/property/${pid}/letter-history`
                        )
                      }
                    />
                  </div>
                  <span className="text-primary-red">
                    Most Recent Letter Level: XXXXX
                  </span>
                </div>
              </div>
              <div>
                <div className="flex justify-between text-primary-teal border-b-[1px] border-primary-gray-light pt-[0.5rem]">
                  <div className="flex items-center">
                    <ViolationIcon />
                    <span className="ml-1">Warn Notes</span>
                  </div>
                  <div className="pb-2">
                    <Button
                      innerText="Add New Warn Note"
                      Icon={AddIcon}
                      iconClass="w-[15px] h-[15px]"
                      extraClass={
                        "shadow-button bg-primary-teal w-fit px-4 border-[1px] border-primary-teal  rounded-full py-1 text-[0.9rem] text-primary-white"
                      }
                      onClick={() =>
                        navigate(
                          `/community/${id}/property/${pid}/warn-notes/add`
                        )
                      }
                    />
                  </div>
                </div>

                {warnNotes.map((warnNote) => {
                  return (
                    <div className="text-primary-black flex items-start border-b-[1px] border-primary-gray-light py-1">
                      <div className="mr-[1rem]">
                        <span className="text-[0.9rem]">{warnNote.title}</span>
                        <div className="flex text-primary-gray-light text-[0.8rem]">
                          <span className="text-nowrap">
                            {formatTimestamp(warnNote.createdAt)}
                          </span>
                        </div>
                      </div>
                      <span className="font-[300] border-l-[2px] border-primary-black px-4">
                        {warnNote.note}
                      </span>
                    </div>
                  );
                })}

                <div
                  onClick={() =>
                    navigate(`/community/${id}/property/${pid}/warn-notes`)
                  }
                  className="flex justify-center cursor-pointer text-primary-black pt-[0.3rem]"
                >
                  View All
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between pb-4">
            <div className="flex gap-[1rem]">
              <Button
                innerText="View Ownership History"
                extraClass={`shadow-button bg-primary-white border-[1px] border-gray  rounded-full text-[0.8rem] text-primary-teal px-4 py-2`}
                onClick={() =>
                  navigate(`/community/${id}/property/${pid}/owner-history`)
                }
              />
              <Button
                innerText="Change Ownership"
                extraClass={`shadow-button bg-primary-white border-[1px] border-gray  rounded-full text-[0.8rem] text-primary-teal px-4 py-2`}
                onClick={() =>
                  navigate(`/community/${id}/property/${pid}/change-ownership`)
                }
              />
            </div>
            <div className="flex gap-[1rem]">
              <Button
                innerText={`Delete Property`}
                extraClass={`shadow-button border-[0.5px] border-primary-red bg-primary-white  rounded-full text-[0.8rem] text-primary-red px-4 py-2`}
                onClick={() => setOpen(true)}
                isLoading={isLoadingDelete}
              />
              <Button
                type="submit"
                innerText="Open Inspection Application"
                isLoading={isLoading}
                disabled={isLoading}
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
              />
            </div>
          </div>
        </div>
      )}
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-4 flex flex-col">
          <div className="text-secondary-gray text-sm">
            Deleting a property will permanently remove all associated data from
            the system. This action cannot be undone.
          </div>
          <div className="text-primary-teal text-sm">
            Do you wish to continue?
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setOpen(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
            />
            <Button
              onClick={() => {
                deleteproperty();
              }}
              isLoading={isLoadingDelete}
              disabled={isLoadingDelete}
              innerText="Yes"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
