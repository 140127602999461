import { useContext, useEffect, useState } from "react";

import Indicator from "../../components/Indicator/Indicator";
import Activitylist from "../../components/ActivityList/ActivityList";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Footer from "../../components/Footer/Footer";

import UserIcon from "../../assets/svg/userIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import PropertiesIcon from "../../assets/svg/propertiesIcon";
import CommunitiesIcon from "../../assets/svg/communitiesIcon";
import CompanyIcon from "../../assets/svg/companyIcon";
import axiosAdmin from "../../utils/helpers/adminAxios";

export default function Dashboard() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [isLoading, setIsLoading] = useState(false);
  const [adminStatsData, setAdminStatsData] = useState({});

  const getStats = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosAdmin.get("/admin/stats");

      if (data.success) {
        setAdminStatsData(data?.applicationStats);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="w-full">
      <Header title={"Dashboard"} buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-2 h-[92%] "
      >
        <div className="flex my-4 border-r-[2px] border-primary-teal">
          <Indicator
            indicatorText="Companies"
            value={adminStatsData?.companies || "-"}
            Icon={CompanyIcon}
          />
          <Indicator
            indicatorText="Communities"
            value={adminStatsData?.communities || "-"}
            Icon={CommunitiesIcon}
          />
          <Indicator
            indicatorText="Properties"
            value={adminStatsData?.propeties || "-"}
            Icon={PropertiesIcon}
          />
          <Indicator
            indicatorText="Users"
            value={adminStatsData?.users || "-"}
            Icon={UserIcon}
          />
          <Indicator
            indicatorText="Open Violations"
            value={adminStatsData?.openViolations || "-"}
            Icon={ViolationIcon}
          />
        </div>
        <div className="px-4 flex flex-col h-[80%]">
          <div className="text-primary-teal text-[2rem] border-b-2 border-secondary-gray pb-2">
            Activity
          </div>
          <div className="overflow-auto flex flex-col pr-4 mt-2">
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
            <Activitylist />
          </div>
        </div>
      </div>
    </div>
  );
}
