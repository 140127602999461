import { useEffect, useState } from "react";

import axios from "../../utils/helpers/axios";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";

export default function CommunityCard({ media_id }) {
  const [imageUrl, setImageUrl] = useState("");

  const getMediaUrl = async () => {
    try {
      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${media_id}`
      );

      if (data.success) {
        setImageUrl(data.media.s3PresignedURL);
      } else setImageUrl("");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (media_id) {
      getMediaUrl();
    }
  }, [media_id]);

  return (
    <div className="w-full h-[8rem]">
      <img
        src={imageUrl || CompanyDashboardDefault}
        className="w-full h-[8rem] object-fit rounded-xl"
        alt=""
      />
    </div>
  );
}
