import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import SwitchInputv2 from "../Input/SwitchInputv2";
import { setCommunityId } from "../../redux/features/communitySlice";

import ArchiveIcon from "../../assets/svg/archiveIconWhite";

export default function AddLetterLevel() {
  const { id, lid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const location = useLocation();
  const levelData = location.state?.levelData;
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [level, setLevel] = useState({
    communityId: id,
    title: "",
    flagForAttorney: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingArchieved, setIsLoadingArchieved] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addLetterLevel = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post("/api/community/letterLevel", level);

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/letter-level`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLevel = async () => {
    try {
      setIsLoading(true);

      let payload = {};
      Object.keys(levelData).forEach((key) => {
        if (levelData[key] !== level[key]) payload[key] = level[key];
      });

      const { data } = await axios.patch("/api/community/letterLevel", {
        communityId: id,
        letterLevelId: lid,
        updateFields: payload,
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/letter-level`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const archieveLevel = async () => {
    try {
      setIsLoadingArchieved(true);

      const { data } = await axios.delete(
        `api/community/letterLevel?communityId=${id}&letterLevelIds=${lid}`
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/letter-level`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  const unarchieveLevel = async () => {
    try {
      setIsLoadingArchieved(true);

      const { data } = await axios.patch(
        `/api/community/letterLevel/unarchieve?communityId=${id}&letterLevelIds=${lid}`,
        {
          archieved: "no",
        }
      );

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/${id}/letter-level`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchieved(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (lid)
      setLevel({
        communityId: id,
        title: levelData?.title,
        flagForAttorney: levelData?.flagForAttorney,
      });
  }, [lid]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title={lid ? "Edit Letter Level" : "Add Letter Level"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col pt-4 px-8 gap-4"
      >
        <div className="flex flex-col gap-[1rem] grow">
          <div className="w-full flex items-center">
            <span className="text-secondary-gray">
              Creating a Level will add new Letter Selection to the application.
              Keep the names short, as it will appear in the application exactly
              entered.{" "}
            </span>
          </div>

          <Input
            label="Level Title"
            placeholder="Enter Level Title"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-4"
            required={true}
            outerClass="w-full  "
            extraClass="border-gray border-2 rounded-full"
            value={level.title}
            onChange={(e) => {
              setLevel((prev) => ({ ...prev, title: e.target.value }));
            }}
            name="title"
          />

          <div className="flex flex-col">
            <div className="flex">
              <SwitchInputv2
                isChecked={level.flagForAttorney === true}
                onChange={(id, value) => {
                  if (value === "add")
                    setLevel((prev) => ({ ...prev, flagForAttorney: true }));
                  if (value === "remove")
                    setLevel((prev) => ({ ...prev, flagForAttorney: false }));
                }}
              />
              <div className="text-secondary-gray">Flag for Attorney</div>
            </div>
            <p className="text-primary-gray-light px-[2.7rem]">
              When a level is flagged “Attorney” and that level is selected
              during an inspection, those properties will be placed into a
              separate queue for review and can be emailed to the attorney of
              record for further enforcement.
            </p>
          </div>
        </div>
        <div className=" flex gap-4 justify-between">
          <div>
            {lid && (
              <Button
                type="submit"
                Icon={ArchiveIcon}
                iconClass={"w-[15px] h-[15px]"}
                innerText={
                  levelData?.archieved === "no" ? "Archive" : "Unarchive"
                }
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
                onClick={() => {
                  if (levelData?.archieved === "no") archieveLevel();
                  else if (levelData?.archieved === "yes") unarchieveLevel();
                }}
                isLoading={isLoadingArchieved}
                spinnerLight={true}
                disabled={isLoadingArchieved}
              />
            )}
          </div>
          <div className="flex gap-4 justify-end">
            <Button
              type="submit"
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full text-[0.8rem] uppercase text-primary-red px-4 py-2"
              onClick={() => navigate(`/community/${id}/letter-level`)}
            />
            <Button
              type="submit"
              innerText={lid ? "Save Changes" : "Add Level "}
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
              onClick={lid ? updateLevel : addLetterLevel}
              spinnerLight={true}
              isLoading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
