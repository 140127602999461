import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";

import Header from "../Header/Header";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Select from "../Input/SelectInput";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";
import CropEasy from "../Crop/CropEasy";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";

import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";

export default function AddUserCompany() {
  const { id, uid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    communitiesAllowed: [],
    avatar: "",
  });
  const [file, setFile] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [openCrop, setOpenCrop] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    if (e.target.files[0]) {
      setFilePreview(URL.createObjectURL(e.target.files[0]));
      setOpenCrop(true);
    }
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const uploadLogo = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post("/api/media/uploadURL/image", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingUpdate(false);
    }
  };

  function put(url, media_id) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingUpdate(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("Avatar uploaded successfully.");
        setUserData((prev) => ({ ...prev, avatar: data.media._id }));
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setIsLoadingUpdate(false);
    }
  };

  const getCompanyRoles = async () => {
    try {
      const { data } = await axios.get("api/company/role");

      if (data.success) {
        const updatedRoles = data.roles.map((item) => ({
          value: item.role,
        }));

        const updatedData = [
          ...updatedRoles,
          {
            value: "company_admin",
          },
          {
            value: "board_user",
          },
        ];

        updatedData.sort((a, b) => a.value.localeCompare(b.value));

        const uniqueRoles = [...new Set(updatedData.map((item) => item.value))];

        const result = uniqueRoles.map((value) => {
          return {
            role:
              value === "company_admin"
                ? "Company Administrator"
                : capitalizeInitials(value, "_"),
            value,
          };
        });
        setRoleDropdown(result);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const getUserById = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/users?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${uid}`
      );

      if (data.success) {
        const user = data.users[0];

        setUserData(
          Object.keys(userData).reduce((acc, key) => {
            if (key in user) acc[key] = user[key];

            return acc;
          }, {})
        );

        getMediaURL(user.avatar);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMediaURL = async (media_id) => {
    if (!media_id) return;

    const { data } = await axios.get(`api/media/mediaURL?mediaId=${media_id}`, {
      mediaId: media_id,
    });

    if (data.success) {
      setCropImagePreview(data.media.s3PresignedURL);
    }
  };

  const addUser = async () => {
    try {
      setIsLoadingUpdate(true);

      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        role: userData.role,
        communitiesAllowed: [id],
      };

      if (userData.avatar) payload.avatar = userData.avatar;

      const { data } = await axios.post("/api/auth/onboard/user", payload);

      if (data.success) {
        toast.success(data.message || "User added successfully.");
        navigate(`/community/${id}/user`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updateUser = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.patch("/api/auth/user", {
        userId: uid,
        ...userData,
        communitiesAllowed: [id],
      });

      if (data.success) {
        toast.success("User updated successfully.");
        navigate(`/community/${id}/user`);
      }
    } catch (error) {
      console.error();
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userData.avatar && !uid) addUser();
    if (file && uid) updateUser();
  }, [userData.avatar]);

  useEffect(() => {
    getCompanyRoles();
  }, []);

  useEffect(() => {
    if (uid) getUserById();
  }, [uid]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      {openCrop && (
        <CropEasy
          {...{
            photoURL: filePreview,
            setPhotoURL: setCropImagePreview,
            openCrop: openCrop,
            setOpenCrop: setOpenCrop,
            setFile: setFile,
            aspect: 1,
          }}
        />
      )}
      <Header
        title={uid ? "Edit User" : "Add User"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      {isLoading && (
        <div className="flex justify-center items-center w-full mt-2">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      {!isLoading && (
        <div
          onClick={() => setToggleSidebar(false)}
          className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
        >
          <div className="flex gap-4">
            <Input
              label="First Name"
              labelClass="text-primary-teal"
              type="text"
              required={true}
              placeholder="First Name"
              name={"firstName"}
              value={userData.firstName}
              onChange={onChangeHandler}
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
            <Input
              label="Last Name"
              labelClass="text-primary-teal"
              type="text"
              placeholder="Last Name"
              name={"lastName"}
              value={userData.lastName}
              onChange={onChangeHandler}
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
          </div>

          <div className="flex gap-4">
            <Input
              label="Email"
              labelClass="text-primary-teal"
              type="text"
              name={"email"}
              value={userData.email}
              onChange={onChangeHandler}
              required={true}
              placeholder="Enter Email ID"
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
            <div className="flex flex-col w-full mt-5">
              <Autocomplete
                value={
                  userData.role === "company_admin"
                    ? "Company Administrator"
                    : capitalizeInitials(userData.role, "_")
                }
                onChange={(event, newValue) => {
                  setUserData((prev) => ({
                    ...prev,
                    role: roleDropdown.find(
                      (roleObj) => roleObj.role === newValue
                    )?.value,
                  }));
                }}
                inputValue={selectedRole}
                onInputChange={(e, newInputValue) => {
                  setSelectedRole(newInputValue);
                }}
                options={roleDropdown.map((role) => role?.role)}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField placeholder="Select Role" {...params} />
                )}
                size="small"
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <Input
              label="Profile Picture"
              labelClass="text-primary-teal"
              type="file"
              inputFieldClass="px-4 placeholder-red-300 file:border-none file:bg-transparent file:text-primary-teal file:cursor-pointer "
              outerClass="w-1/2"
              extraClass="border-gray border-[1px] shadow-inputShadow rounded-full"
              onChange={handleChange}
            />
            <div className="space-y-2">
              <div className="text-secondary-gray-light">Preview Image</div>
              <div className=" h-[8rem] w-[8rem] border-[1px] border-gray rounded-xl shadow-inputShadow">
                <img
                  src={cropImagePreview || CompanyDashboardDefault}
                  className="w-full rounded-xl h-full object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="flex space-x-4 mt-auto justify-end">
            <Button
              innerText="Cancel"
              onClick={() => {
                navigate(`/community/${id}/user`);
              }}
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-sm uppercase text-primary-red"
            />
            {uid ? (
              <Button
                type="submit"
                innerText={"Edit user"}
                onClick={() => {
                  file ? uploadLogo() : updateUser();
                }}
                isLoading={isLoadingUpdate}
                spinnerLight={true}
                disabled={
                  isLoadingUpdate ||
                  Object.entries(userData).some(([key, value]) => {
                    if (
                      userData.role === "community_admin" &&
                      key === "communitiesAllowed"
                    )
                      return value.length === 0;

                    if (key === "avatar") return false;

                    return value === "";
                  })
                }
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white"
              />
            ) : (
              <Button
                type="submit"
                innerText={"Save Changes"}
                onClick={() => {
                  filePreview ? uploadLogo() : addUser();
                }}
                isLoading={isLoadingUpdate}
                spinnerLight={true}
                disabled={
                  isLoadingUpdate ||
                  Object.entries(userData).some(([key, value]) => {
                    if (
                      userData.role === "community_admin" &&
                      key === "communitiesAllowed"
                    )
                      return value.length === 0;

                    if (key === "avatar") return false;

                    return value === "";
                  })
                }
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
