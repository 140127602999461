import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Pagination from "../../components/Pagination/Pagination";
import PhotoViewCard from "../../components/PhotoViewCard/PhotoViewCard";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

export default function PhotoHistory() {
  const { id, pid } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();
  const imageUploadRef = useRef("");
  const navigate = useNavigate();

  const [filePreview, setFilePreview] = useState("");
  const [file, setFile] = useState("");
  const [property, setProperty] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [community, setCommunity] = useState({});
  const [photoHistories, setPhotoHistories] = useState([]);
  const [totalPhotoHistory, setTotalPhotoHistory] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);

  function handleChange(e) {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFilePreview(URL.createObjectURL(e.target.files[0]));
    }
  }

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPhotoHistory = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/photoHistories?propertyId=${pid}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) {
        setTotalPhotoHistory(data.matchedPhotoHistoryCount);
        setPhotoHistories(data.histories);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoadingProperty(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingProperty(false);
    }
  };

  const deletePhoto = async (id, setIsLoadingDelete) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/photoHistory?propertyPhotoId=${id}`
      );

      if (data.success) {
        toast.success(data.message);
        getPhotoHistory();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const uploadPhoto = async () => {
    try {
      setIsLoadingUploadImage(true);

      const { data } = await axios.post("/api/job/uploadPropertyPhoto", {
        fileName: file.name,
        mimeType: file.type,
        associatedWithJob: "no",
        communityId: id,
        propertyId: pid,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingUploadImage(false);
    }
  };

  function put(url, media_id) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingUploadImage(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("Image uploaded successfully.");
        getPhotoHistory();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUploadImage(false);
    }
  };

  useEffect(() => {
    if (pid) getPhotoHistory();
  }, [pid, rowsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && pid) getPropertyDetails();
  }, [id, pid]);

  useEffect(() => {
    if (file) uploadPhoto();
  }, [file]);

  return (
    <div className="w-full">
      <input
        type="file"
        className="hidden"
        ref={imageUploadRef}
        onChange={handleChange}
      />
      <Header
        title="Photo History"
        buttonText={community?.legalName}
        address={
          isLoadingProperty
            ? " "
            : `${property?.streetNumber} ${property?.streetName},
            ${property?.addressLine2 ? property?.addressLine2 + "," : ""}
             ${property?.city}, ${property?.state}`
        }
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="overflow-auto min-h-[6rem] w-full flex grow">
          {isLoading && (
            <div className="flex justify-center flex-1">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          <div className=" grid grid-cols-4 gap-4">
            {!isLoading &&
              photoHistories.map((photoHistory) => {
                return (
                  <PhotoViewCard
                    photoHistory={photoHistory}
                    download={true}
                    getPhotoHistory={getPhotoHistory}
                    deletePhoto={deletePhoto}
                  />
                );
              })}
          </div>
        </div>
        <div className="flex pb-[1rem]">
          <Button
            type="file"
            innerText="Upload Image"
            extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
            onClick={() => {
              imageUploadRef.current.click();
            }}
            isLoading={isLoadingUploadImage}
            disabled={isLoadingUploadImage}
            spinnerLight={true}
          />
        </div>
        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalPhotoHistory}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
