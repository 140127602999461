import ProCamLogo from "../../assets/svg/proCamLogo";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex gap-x-4 items-center bg-gradient-to-r from-secondary-teal to-tertiary-teal px-4 fixed bottom-0 w-full z-20">
      <ProCamLogo className="w-[4rem] text-primary-white " />
      <p className="text-primary-white text-sm">
        &copy; {currentYear} Procam Inspect. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
