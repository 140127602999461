import { useContext } from "react";

import Button from "../Button/Button";
import { ToggleSidebarContext } from "../Layout/Layout";

import MenuIcon from "../../assets/svg/menuIcon";

function GeneralHeader({ title, buttonText, onClick = () => {} }) {
  const { toggleSidebar, setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="flex justify-between pb-[0.5rem] border-b-2 border-primary-white">
      <div className="flex gap-[0.5rem] items-center">
        <div
          onClick={() => setToggleSidebar(!toggleSidebar)}
          className="h-[2.2rem] w-[2.2rem] cursor-pointer rounded-full border border-white py-1 px-2"
        >
          <MenuIcon className="text-primary-white w-full" />
        </div>
        <div className="text-[1.6rem] text-primary-white ">{title}</div>
      </div>
      {buttonText && (
        <Button
          innerText={buttonText}
          extraClass={`shadow-button bg-primary-white w-fit px-4 border-[1px] border-gray rounded-full py-1 text-[0.9rem] text-primary-teal`}
          onClick={onClick}
        />
      )}
    </div>
  );
}

export default GeneralHeader;
