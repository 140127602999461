import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

import axios from "../../utils/helpers/axios";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Community from "../../components/Community/Community";
import Header from "../../components/Header/Header";
import Pagination from "../../components/Pagination/Pagination";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";

import AlphabetSortIcon from "../../assets/svg/alphabetSortIcon";
import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";

export default function Communities() {
  const auth = useSelector((state) => state.auth);
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [totalCommunities, setTotalCommunities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortFields, setSortFields] = useState("updatedAt");
  const [sortOrder, setSortOrder] = useState("-1");
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [communities, setCommunities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getCommunities = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=${sortFields}&sortOrders=${sortOrder}&${
          filterFields.field
            ? "filterFields=" +
              filterFields.field +
              "&filterValues=" +
              filterFields.value +
              "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setCommunities(data.communities);
        setTotalCommunities(data.matchedCommunitiesCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommunities();
  }, [currentPage, rowsPerPage, sortFields, sortOrder, filterFields.field]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getCommunities();
    if (searchQuery.length > 2) getCommunities();
  }, [searchQuery]);

  return (
    <div className="w-full">
      <Header
        title="Communities"
        buttonText={auth.user.company.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4">
          <div className="grow">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search By Community Name"
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="flex items-center">
            <SwitchInputv2
              isChecked={filterFields.value === "false"}
              onChange={(id, value) => {
                if (value === "add")
                  setFilterFields({
                    field: "isActive",
                    value: "false",
                  });

                if (value === "remove")
                  setFilterFields({
                    field: "",
                    value: "",
                  });
              }}
            />

            <div className="text-primary-gray text-sm">Show Inactive</div>
          </div>

          <div className="flex gap-4 items-center">
            <Button
              Icon={AlphabetSortIcon}
              iconClass=" w-[1.4rem] h-[1.4rem]"
              extraClass="relative w-[2rem] h-[2rem] flex items-center text-primary-teal text-primary-teal py-2 px-1 text-sm rounded-full border-primary-teal border-[0.5px]"
              onClick={() => {
                setSortFields("letterName");
                setSortOrder(sortOrder === "1" ? "-1" : "1");
              }}
            />

            <Link to="/company/communities/add">
              <Button
                Icon={AddIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add New Community"
              />
            </Link>
          </div>
        </div>

        <div className="overflow-auto grid grid-cols-3 gap-8 px-4 min-h-[6rem] mt-4 pb-4">
          {isLoading && (
            <div className="col-span-full flex justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading &&
            communities.map((community) => (
              <Community
                key={community._id}
                title={community.legalName}
                communityItems={[community.coverPicture]}
                communityId={community._id}
              />
            ))}
          {!isLoading && communities.length === 0 && (
            <div className="flex flex-1 justify-center text-primary-gray">
              No communities found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalCommunities}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowPerPage) => {
            setRowsPerPage(rowPerPage);
          }}
        />
      </div>
    </div>
  );
}
