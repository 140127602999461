import { Button } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import PhotoViewCard from "../../components/PhotoViewCard/PhotoViewCard";
import { ClipLoader } from "react-spinners";
import Header from "../../components/Header/Header";
import { useEffect, useRef, useState } from "react";
import axios from "../../utils/helpers/axios";
import { useParams } from "react-router-dom";

function PhotoHistory() {
  const { streetName, streetNumber, ownerToken } = useParams();

  const [photoHistories, setPhotoHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOwnerViewData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/owner/propertyDetails?streetName=${streetName}&streetNumber=${streetNumber}&ownerAccessToken=${ownerToken}`
      );

      if (data.success) {
        setPhotoHistories(data?.propertyDetails?.[0]?.propertyPhotos);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOwnerViewData();
  }, []);

  return (
    <div className="w-full">
      <Header title="Photo History" address={`${streetNumber} ${streetName}`} />
      <div className="px-8 flex flex-col gap-4 h-[92%] mt-4">
        <div className="overflow-auto min-h-[6rem] w-full flex grow">
          {isLoading && (
            <div className="flex justify-center flex-1">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading && photoHistories?.length === 0 && (
            <div className=" flex justify-center text-primary-gray">
              No Photos Found
            </div>
          )}

          <div className=" grid grid-cols-4 gap-4">
            {!isLoading &&
              photoHistories.map((photoHistory) => {
                return (
                  <PhotoViewCard
                    photoHistory={photoHistory}
                    download={false}
                    showDelete={false}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoHistory;
