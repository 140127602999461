import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import Header from "../../components/Header/Header";
import ConcernHistoryCard from "../../components/ConcernHistoryCard/ConcernHistoryCard";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Button from "../../components/Button/Button";
import { setCommunityId } from "../../redux/features/communitySlice";

export default function MaintainanceConcernHistory() {
  const { id, pid } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [community, setCommunity] = useState({});
  const [property, setProperty] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalViolationHistory, setTotalViolationHistory] = useState(0);
  const [violationHistories, setViolationHistories] = useState([]);
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getPropertyDetails = async () => {
    try {
      setIsLoadingProperty(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingProperty(false);
    }
  };

  const getViolationHistory = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/violationHistories?propertyId=${pid}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=status&sortOrders=-1`
      );

      if (data.success) {
        setTotalViolationHistory(data.matchedViolationHistoryCount);
        setViolationHistories(data.histories);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (pid) getViolationHistory();
  }, [pid, rowsPerPage, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (id && pid) getPropertyDetails();
  }, [id, pid]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Maintainance Concern History"
        buttonText={community?.legalName}
        address={
          isLoadingProperty
            ? " "
            : `${property?.streetNumber}, ${property?.streetName}, ${property?.city}, ${property?.state}`
        }
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />

      <div
        onClick={() => setToggleSidebar(false)}
        className="relative overflow-auto flex-1 flex flex-col gap-[1.4rem] p-[1rem]"
      >
        {isLoading && (
          <div className="flex flex-1 justify-center p-[2rem]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          violationHistories.map((violation) => {
            return (
              <ConcernHistoryCard
                violation={violation}
                getViolationHistory={getViolationHistory}
              />
            );
          })}
        {!isLoading && violationHistories.length === 0 && (
          <div className="text-primary-gray flex justify-center">
            No Violation History Found
          </div>
        )}
      </div>

      {violationHistories.length !== 0 && (
        <div className="relative bg-white z-10 w-full pt-[1rem] px-[2rem]">
          <Button
            innerText={"Download All History"}
            extraClass={
              "hadow-button px-4 py-1 border-[1px] border-primary-teal bg-primary-teal  rounded-full  text-[0.9rem] text-primary-white"
            }
          />
          <Pagination
            isStatic={false}
            currentPage={currentPage}
            totalCount={totalViolationHistory}
            pageSize={rowsPerPage}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
          <Pagination
            isStatic={true}
            currentPage={rowsPerPage}
            onPageChange={(rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
            }}
          />
        </div>
      )}
    </div>
  );
}
