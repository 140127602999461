import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";

import CommunityCard from "../CommunityCard/CommunityCard";
import { setCommunityId } from "../../redux/features/communitySlice";

import TitleShadow from "../../assets/svg/titleShadow";

export default function Community({ title, communityItems, communityId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      className={
        "w-full h-[8rem] rounded-xl border-[1px] border-gray shadow-tooltip cursor-pointer"
      }
    >
      <div className="relative w-full h-full ">
        {communityItems.map((media_id, index) => (
          <CommunityCard title={title} key={index} media_id={media_id} />
        ))}
        <div
          onClick={() => {
            // localStorage.setItem("selectedCommunityId", communityId);
            dispatch(setCommunityId(communityId));
            navigate(`/community/dashboard/${communityId}`);
          }}
          className="absolute top-[80px] -left-[8px] bg-primary-white shadow-inputShadow flex justify-start items-center px-2 w-[300px] h-[40px] rounded-r-xl border-[1px] border-offWhite text-primary-teal font-base font-[400] text-nowrap line-clamp-1"
        >
          {title}
          <div className="absolute -top-[3px] left-0">
            <TitleShadow />
          </div>
        </div>
      </div>
    </div>
  );
}
