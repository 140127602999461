import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../components/Button/Button";
import Indicator from "../../components/Indicator/Indicator";
import Input from "../../components/Input/Input";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import PropertyDropDown from "../../components/PropertyDropdown/PropertyDropdown";
import axios from "../../utils/helpers/axios";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import UserIcon from "../../assets/svg/userIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import CommunitiesInspectionIcon from "../../assets/svg/communitiesInspectionIcon";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";
import CommunitiesIcon from "../../assets/svg/communitiesIcon";
import ReportIcon from "../../assets/svg/reportIcon";
import JobIcon from "../../assets/svg/jobIcon";

export default function CompanyDashboard() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [properties, setProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [company, setCompany] = useState({});
  const [statsData, setStatsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getStats = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/company/stats");

      if (data.success) {
        setStatsData(data?.companyStats);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProperties = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?pageNum=1&rowsPerPage=5&sortFields=updatedAt&sortOrders=-1&searchQuery=${searchQuery}`
      );

      if (data.success) {
        setProperties(data.properties);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(`/api/company/info`);

      if (data.success) setCompany(data.company);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMediaURL = async () => {
    try {
      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${company.logo}`
      );

      if (data.success) {
        setFilePreview(data.media.s3PresignedURL);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (searchQuery.length > 2) getProperties();
  }, [searchQuery]);

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    if (company.logo) getMediaURL();
  }, [company.logo]);

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="w-full">
      <Header title="Dashboard" userImg={true} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className={"w-full relative"}>
          <Input
            iconClass="absolute left-4 text-secondary-gray"
            Icon={SearchIcon}
            placeholder="Search by Address / Street / Owner’s Name"
            inputFieldClass="placeholder:text-primary-gray-light"
            extraClass="relative border-gray border-[1px] py-[0.1rem] rounded-full px-12"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <PropertyDropDown
            properties={properties}
            isLoading={isLoading}
            searchQuery={searchQuery}
          />
        </div>

        <div className="w-full h-[17rem] grow border-[1px] border-gray shadow-inputShadow hover:shadow-inputShadowActive rounded-3xl">
          <img
            src={filePreview || CompanyDashboardDefault}
            alt=""
            className="w-full h-full object-fit rounded-3xl"
          />
        </div>

        <div className="flex my-4 border-r-[2px] border-primary-teal">
          <Indicator
            indicatorText="Communities"
            value={statsData?.communities || "-"}
            Icon={CommunitiesIcon}
          />
          <Indicator
            indicatorText="Properties"
            value={statsData?.propeties || "-"}
            Icon={PropertiesIcon}
          />
          <Indicator
            indicatorText="Users"
            value={statsData?.users || "-"}
            Icon={UserIcon}
          />
          <Indicator
            indicatorText="Open Violations"
            value={statsData?.openViolations || "-"}
            Icon={ViolationIcon}
          />
        </div>

        <div className="grid grid-cols-2 grid-rows-3 gap-4 px-4 pb-6">
          <Link to="/company/jobs">
            <Button
              Icon={JobIcon}
              iconClass="w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full w-full"
              innerText="Jobs"
            />
          </Link>

          <Link to="">
            <Button
              Icon={ReportIcon}
              iconClass=" w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full w-full"
              innerText="Reports"
            />
          </Link>

          <Link to="/company/communities">
            <Button
              Icon={CommunitiesIcon}
              iconClass=" w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full w-full"
              innerText="Communities"
            />
          </Link>

          <Link to="/company/properties">
            <Button
              Icon={PropertiesIcon}
              iconClass=" w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full w-full"
              innerText="Properties"
            />
          </Link>

          <Link className="col-span-full" to="">
            <Button
              Icon={CommunitiesInspectionIcon}
              iconClass=" w-[20px] h-[20px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full w-full "
              innerText="Community Inspection"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
