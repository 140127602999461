import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { UserColumns } from "../../constants/TableColumns/Columns";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Table from "../../components/Table/Table";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";
import Select from "../../components/Input/SelectInput";
import Pagination from "../../components/Pagination/Pagination";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/adminAxios";

import SearchIcon from "../../assets/svg/searchIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function User() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterFields, setFilterFields] = useState({
    field: ["active"],
    value: ["true"],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (id, data) => {
    setCompany(data);
    setFilterCompany(data.companyName);

    if (data.companyName === "All") {
      setFilterFields((prev) => ({
        field: [prev.field[0]],
        value: [prev.value[0]],
      }));
    } else {
      setFilterFields((prev) => ({
        field: [prev.field[0], "company"],
        value: [prev.value[0], data._id],
      }));
    }
  };

  const filterCompaniesByName = (name) => {
    if (name === "" || name === "All") return companies;

    return companies.filter((company) =>
      company.companyName.toLowerCase().includes(name.toLowerCase())
    );
  };

  const getCompanyDropdown = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/admin/getCompanies?sortFields=updatedAt&sortOrders=-1&filterFields=active&filterValues=true"
      );

      if (data.success) {
        data.companies.sort((a, b) =>
          a.companyName.localeCompare(b.companyName)
        );
        data.companies.unshift({ companyName: "All" });
        setCompanies(data.companies);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserFunction = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/admin/getUsers?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          "filterFields=" +
          filterFields.field.join(",") +
          "&filterValues=" +
          filterFields.value.join(",") +
          "&"
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setUsers(data.users);
        setTotalUsers(data.matchedUserCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDropdown();
  }, []);

  useEffect(() => {
    getUserFunction();
  }, [currentPage, rowsPerPage, filterFields.value]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getUserFunction();
    if (searchQuery.length > 2) getUserFunction();
  }, [searchQuery]);

  return (
    <div className="w-full">
      <Header title="Users" buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex justify-between">
          <div className="flex grow gap-4">
            <Input
              iconClass="absolute top-[22%] left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Users by Name, Email or Company Name"
              inputFieldClass="placeholder:text-primary-gray"
              onChange={(e) => onChange(e)}
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              outerClass={"w-full"}
            />
            <Select
              iconClass="absolute top-[22%] left-2 text-secondary-gray"
              placeholder="Select Company"
              inputFieldClass="placeholder:text-primary-gray"
              dropdownData={filterCompaniesByName(filterCompany)}
              id="company"
              fieldName="companyName"
              value={filterCompany}
              handleChange={handleChange}
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-8"
              outerClass={"w-2/3"}
              onHandleInputChange={(e) => {
                setFilterCompany(e.target.value);
              }}
            />
          </div>

          <div className="flex gap-4 ml-4">
            <div className="flex items-center">
              <SwitchInputv2
                isChecked={filterFields.value[0] === "false"}
                onChange={(id, value) => {
                  if (value === "add")
                    setFilterFields((prev) => ({
                      ...prev,
                      value: ["false", ...prev.value.slice(1)],
                    }));

                  if (value === "remove")
                    setFilterFields((prev) => ({
                      ...prev,
                      value: ["true", ...prev.value.slice(1)],
                    }));
                }}
              />
              <div className="text-primary-gray text-[1rem]">Show Inactive</div>
            </div>
            <Link to="/user/add">
              <Button
                Icon={AddIcon}
                iconClass="w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add New User"
              />
            </Link>
          </div>
        </div>

        <div className=" overflow-auto min-h-[6rem] max-h-[86%] px-2 flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={UserColumns}
              tableData={users}
            />
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalUsers}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowPerPage) => {
            setRowsPerPage(rowPerPage);
          }}
        />
      </div>
    </div>
  );
}
