import ClipLoader from "react-spinners/ClipLoader";

const Button = ({
  type = "button",
  innerText,
  Icon,
  iconClass,
  extraClass,
  isLoading = false,
  disabled,
  spinnerLight = false,
  iconLeft = false,
  error,
  title,
  ...rest
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`disabled:opacity-80 flex items-center justify-center space-x-2 shadow-button hover:-translate-y-[2px] hover:scale-[1.025] ${extraClass}`}
      title={title}
      {...rest}
    >
      {Icon && <Icon className={`w-6 h-6 ${iconClass} `} />}

      {innerText && (
        <span className={`${iconLeft && "order-2 mx-2"}`}>{innerText}</span>
      )}

      {isLoading && (
        <ClipLoader color={spinnerLight ? "#FFF" : "#0080A2"} size={16} />
      )}
    </button>
  );
};

export default Button;
