import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/adminAxios";
import { ToggleSidebarContext } from "../Layout/Layout";
import TextArea from "../Input/TextArea";

import ViolationIcon from "../../assets/svg/violationIcon";

export default function AddViolation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [violations, setViolations] = useState({
    title: "",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setViolations((prev) => ({ ...prev, [name]: value }));
  };

  const getViolationId = async () => {
    try {
      const { data } = await axios.get(
        `/admin/getViolation?pageNum=1&rowsPerPage=30&sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const violation = data.violations[0];

        setViolations({
          title: violation.title,
          content: violation.content,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addViolation = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/admin/addViolation", [violations]);

      if (data.success) {
        toast.success("Violation added successfully");

        navigate("/violation-library");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editViolations = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.patch("/admin/updateViolation", {
        violationId: id,
        updateFields: violations,
      });

      if (data.success) {
        toast.success(data.message);
        navigate("/violation-library");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) getViolationId();
  }, [id]);

  return (
    <div className="w-full">
      <Header
        Icon={ViolationIcon}
        title={id ? "Edit Violation" : "Add Violation"}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex gap-4 grow">
          <TextArea
            label="Violation Title"
            placeholder="Enter Violation Title"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-6"
            required={true}
            onChange={onChange}
            name={"title"}
            outerClass="w-full"
            extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full break-words"
            value={violations.title}
            info={`Violation Title is the headline text that will show in the application to quickly identify the violation. This text will not appear in the letter.  We recommend keeping it short. Examples: "Garbage Cans" or "Dead Turfgrass`}
          />
          <TextArea
            label="Violation"
            labelClass="text-primary-teal"
            placeholder="Enter Violation Content"
            inputFieldClass="px-6"
            required={true}
            type="text"
            name={"content"}
            onChange={onChange}
            outerClass="w-full"
            extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full break-words"
            value={violations.content}
            info={
              "Violation Text is the actual violation language that will appear in letters sent to owners."
            }
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            type="submit"
            innerText="Cancel"
            extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white px-4 text-sm py-2 rounded-full text-primary-red"
            onClick={() => navigate("/violation-library")}
          />

          {id ? (
            <Button
              type="submit"
              innerText="Save Changes"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-sm px-4 text-primary-white"
              isLoading={isLoading}
              disabled={isLoading || !violations.title || !violations.content}
              onClick={editViolations}
              spinnerLight={true}
            />
          ) : (
            <Button
              type="submit"
              innerText="Add violation"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-sm px-4 text-primary-white"
              isLoading={isLoading}
              disabled={isLoading || !violations.title || !violations.content}
              onClick={addViolation}
              spinnerLight={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
