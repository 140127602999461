import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Header from "../../components/Header/Header";
import LetterHistoryCard from "../../Pages/OwnersView/LetterHistoryCard";
import axios from "../../utils/helpers/axios";

function LetterHistory() {
  const { streetName, streetNumber, ownerToken } = useParams();

  const [letterHistories, setLetterHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOwnerViewData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/owner/propertyDetails?streetName=${streetName}&streetNumber=${streetNumber}&ownerAccessToken=${ownerToken}`
      );

      if (data.success) {
        setLetterHistories(data?.propertyDetails?.[0]?.letterHistory || []);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOwnerViewData();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Letter History"
        address={`${streetNumber}, ${streetName}`}
      />
      <div className="relative overflow-auto flex-1 flex flex-col gap-[1.4rem] px-[2rem] py-[1rem]">
        {isLoading && (
          <div className="flex justify-center p-[2rem]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading &&
          letterHistories.map((letter) => {
            return <LetterHistoryCard letter={letter} />;
          })}

        {!isLoading && letterHistories.length === 0 && (
          <div className="text-primary-gray flex justify-center">
            No Letter History Found
          </div>
        )}
      </div>
    </div>
  );
}

export default LetterHistory;
