import { useContext, useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate, useParams } from "react-router-dom";

import Button from "../Button/Button";
import { ToggleSidebarContext } from "../Layout/Layout";
import UserProfileModel from "../UserProfileModel/UserProfileModel";
import axiosInstance from "../../utils/helpers/axios";

import MenuIcon from "../../assets/svg/menuIcon";
import ProfileDefaultIcon from "../../assets/svg/profileDefaultIcon.svg";
import ProcamLogo from "../../assets/svg/procamLogo.svg";
import { use } from "react";

export default function Header({
  title,
  address,
  buttonText,
  userImg,
  dropdown = false,
  onClick = () => {},
  communitiesAllowed = [],
}) {
  const { toggleSidebar, setToggleSidebar } = useContext(ToggleSidebarContext);
  const model = useRef(null);
  const navigate = useNavigate();
  const { id: activeCommunity } = useParams();
  const dropdownRef = useRef(null);

  const [userData, setUserData] = useState({});
  const [filePreview, setFilePreview] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openDropdown, setOpendropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUserInfo = async () => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get("/api/auth/profile");

      if (data.success) {
        setUserData(data.userProfile[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMediaURL = async () => {
    const { data } = await axiosInstance.get(
      `api/media/mediaURL?mediaId=${userData.avatar}`
    );

    if (data.success) {
      setFilePreview(data.media.s3PresignedURL);
    }
  };

  const getCompanyLogoMediaURL = async () => {
    const { data } = await axiosInstance.get(
      `api/media/mediaURL?mediaId=${userData.companyDetails.logo}`
    );

    if (data.success) {
      setCompanyLogo(data.media.s3PresignedURL);
    }
  };

  useEffect(() => {
    if (userImg) getUserInfo();
  }, [userImg]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (model.current && !model.current.contains(event.target)) {
        setOpenModel(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [model]);

  useEffect(() => {
    if (userData.avatar) getMediaURL();
    if (userData.companyDetails?.logo) getCompanyLogoMediaURL();
  }, [userData.avatar, userData.companyDetails?.logo]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpendropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className="h-[8%] flex-0 relative w-full bg-primary-white px-8">
      <div className="w-full h-full border-b-2 pb-6 pt-4 border-primary-teal flex justify-between items-center ">
        <div className="flex text-primary-teal text-xl">
          <div
            onClick={() => setToggleSidebar(!toggleSidebar)}
            className="bg-primary-teal flex justify-center items-center w-[30px] h-[30px] rounded-full mr-2 cursor-pointer"
          >
            <MenuIcon className="text-primary-white w-[14px]" />
          </div>
          <div className="text-[1.6rem]">{title}</div>

          {address && (
            <div className="ml-3 px-2 border-l-[2px] border-seconday-gray text-primary-gray-light text-base flex items-center">
              {address}
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <div ref={model} className="relative flex items-center w-12 h-12">
            {userImg && (
              <img
                src={filePreview || ProfileDefaultIcon}
                alt=""
                onClick={() => setOpenModel((prev) => !prev)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            )}
            {openModel && (
              <UserProfileModel
                setOpenModel={setOpenModel}
                openModel={openModel}
                isLoading={isLoading}
                userData={userData}
                filePreview={filePreview}
                companyLogo={companyLogo}
              />
            )}
          </div>
          <div className="flex items-center gap-x-4">
            <div className=" relative flex items-center">
              {buttonText && (
                <Button
                  innerText={buttonText}
                  extraClass={`shadow-button bg-primary-white w-fit px-6 border-[1px] border-gray rounded-full py-1 text-[0.9rem] text-primary-teal w-full`}
                  onClick={onClick}
                />
              )}
              {dropdown && (
                <ArrowDropDownIcon
                  className="text-primary-teal absolute right-0 top-1 cursor-pointer"
                  onClick={() => {
                    setOpendropdown((prev) => !prev);
                  }}
                />
              )}
              {dropdown && openDropdown && (
                <div
                  ref={dropdownRef}
                  className="absolute top-10 right-0 bg-primary-white shadow-button border-[1px] border-gray rounded-lg py-2 px-4 z-[10000] w-[20rem] h-[30rem] overflow-y-auto"
                >
                  {communitiesAllowed.map((community) => (
                    <div
                      className={`p-2 text-primary-teal text-[0.9rem] cursor-pointer text-nowrap truncate ${
                        activeCommunity === community?._id
                          ? "rounded-xl bg-primary-teal text-white"
                          : ""
                      }`}
                      key={community?._id}
                      onClick={() => {
                        navigate(`/community/dashboard/${community?._id}`);
                      }}
                    >
                      {community?.legalName}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <img src={ProcamLogo} alt="" className="w-24" />
          </div>
        </div>
      </div>
    </div>
  );
}
