import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Job from "./Job";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";

import JobIcon from "../../assets/svg/jobIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function ReviewJob() {
  const { id, jid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [community, setCommunity] = useState({});
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobDetail, setJobDetail] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [loadMoreDisabled, setLoadMoreDisabled] = useState(false);
  const [letterLevelDropdown, setLetterLevelDropdown] = useState([]);
  const [reviewJobUpdate, setReviewJobUpdate] = useState({
    communityId: id,
    jobId: jid,
    approveJobViolations: [],
    rejectJobViolations: [],
    updateLetterTemplates: [],
  });
  const [deletePhotoId, setDeletePhotoId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingLoadMore, setIsLoadingLoadMore] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const rowPerPage = 25;

  const getJobDetail = async (currentPage) => {
    try {
      if (currentPage > 1) setIsLoadingLoadMore(true);
      else setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/getDetail?communityId=${id}&jobId=${jid}&pageNum=${currentPage}&rowsPerPage=${rowPerPage}`
      );

      if (data.success) {
        setTotalJobs(data.propertyCount);
        setJobs((prev) => [...prev, ...data.properties]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsLoadingLoadMore(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getJob = async () => {
    try {
      const { data } = await axios.get(
        `/api/job/getListing?sortFields=updated&sortOrders=-1&filterFields=_id&filterValues=${jid}`
      );

      if (data.success) {
        setJobDetail(data.jobs[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterLevel = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/levelAssociatedLetterTemplate?communityId=${id}`
      );

      if (data.success) {
        setLetterLevelDropdown(
          data.letterAssociatedWithLevels.map((letter) => ({
            ...letter,
            levelTitle: letter.levelContent.title,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateJob = async (loading = true) => {
    try {
      if (loading) setIsLoadingUpdate(true);
      const { data } = await axios.patch("/api/job/reviewJob", reviewJobUpdate);

      if (data.success) {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const deletePhoto = async (id) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/photoHistory?propertyPhotoId=${id}`
      );

      if (data.success) return true;
      else return false;
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);

      return false;
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const deleteAllPhotos = async () => {
    try {
      const deletePromises = deletePhotoId.map((id) => deletePhoto(id));

      const results = await Promise.all(deletePromises);

      if (results.every((result) => result === true))
        toast.success("All photos deleted successfully");
      else toast.error("An error occurred while deleting photos");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting photos");
    }
  };

  const saveAndApprove = async () => {
    try {
      setIsLoadingSave(true);
      const { data } = await axios.post("/api/job/approveJobReview", {
        jobId: jid,
      });

      if (data.success) {
        toast.success(data.message);
        navigate(-1);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingSave(false);
    }
  };

  useEffect(() => {
    if (pageNumber === 1) getJobDetail(1);
  }, []);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (jid) getJob();
  }, [jid]);

  useEffect(() => {
    if (pageNumber === Math.ceil(totalJobs / rowPerPage))
      setLoadMoreDisabled(true);
  }, [pageNumber, totalJobs]);

  useEffect(() => {
    getLetterLevel();
  }, []);

  return (
    <div className="w-full">
      <Header
        title="Review Job"
        address={jobDetail?.jobName}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />

      <div className="px-8 flex flex-col gap-4 h-[92%] mt-4">
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading && (
          <div>
            {jobs.length > 0 && (
              <div className="overflow-auto pr-4">
                {jobs.map((job) => {
                  const { propertyDetails } = job;
                  const { propertySelectedPhotos } = job;
                  const propertyJobViolations = job.propertyJobOpenViolations;
                  const { propertySelectedLetter } = job;

                  return (
                    <Job
                      {...{
                        propertyDetails,
                        propertySelectedPhotos,
                        propertyJobViolations,
                        propertySelectedLetter,
                        letterLevelDropdown,
                        setReviewJobUpdate,
                        reviewJobUpdate,
                        deletePhotoId,
                        setDeletePhotoId,
                      }}
                    />
                  );
                })}
              </div>
            )}

            {jobs.length === 0 && (
              <div className="text-primary-gray flex justify-center items-center grow h-full">
                No jobs violations found
              </div>
            )}

            <div className="flex justify-between py-4 ">
              <Button
                type="submit"
                Icon={AddIcon}
                iconClass=" w-[20px] h-[20px] lg:h-4"
                innerText="Load More"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-[0.8rem] text-primary-white p-4"
                onClick={() => {
                  const nextPage = pageNumber + 1;
                  setPageNumber(nextPage);
                  getJobDetail(nextPage);
                }}
                isLoading={isLoadingLoadMore}
                disabled={loadMoreDisabled || isLoadingLoadMore}
                spinnerLight={true}
              />

              <div className="flex justify-between gap-4">
                <Button
                  type="submit"
                  innerText="Save & Approve"
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-[0.8rem] text-primary-white p-4"
                  onClick={() => {
                    updateJob(false);
                    saveAndApprove();
                  }}
                  isLoading={isLoadingSave}
                  disabled={isLoadingSave}
                  spinnerLight={true}
                />
                <Button
                  type="submit"
                  innerText="Save Changes"
                  extraClass="shadow-button border-[0.5px] border-primary-white bg-primary-white rounded-full py-2 text-[0.8rem] text-primary-teal p-4 border-gray"
                  onClick={() => {
                    updateJob();
                    if (deletePhotoId.length) deleteAllPhotos();
                  }}
                  isLoading={isLoadingUpdate}
                  disabled={isLoadingUpdate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
