import { useContext, useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { violationLibraryCompanyColumns } from "../../constants/TableColumns/Columns";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../utils/helpers/axios";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import csvIcon from "../../assets/svg/csvIcon";
import ImportIcon from "../../assets/svg/importIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";
import { useSelector } from "react-redux";

export default function ViolationLibraryCompany() {
  const csvUploadRef = useRef("");
  const { user } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const importFromMasterAdmin = searchParams.get("importFromMasterAdmin");
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [violations, setViolations] = useState([]);
  const [totalViolations, setTotalViolations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingCSVUpload, setIsLoadingCSVUpload] = useState(false);
  const [isLoadingViolationCSVUpload, setIsLoadingViolationCSVUpload] =
    useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked) {
      setSelectedViolations([
        ...selectedViolations,
        { id: row._id, title: row.title, content: row.content },
      ]);
    } else {
      setSelectedViolations(
        selectedViolations.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/company/violation-library/edit/${row._id}`);
  };

  function csvhandleChange(e) {
    if (e.target.files[0]) {
      uploadCSV(e.target.files[0]);
      e.target.value = null;
    }
  }

  const getViolations = async (param) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/violation/${param}?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=title&sortOrders=1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setViolations(data.violations);
        setTotalViolations(data.matchedViolationCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addViolation = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post(
        "/api/company/violation",
        selectedViolations.map((violation) => ({
          title: violation.title,
          content: violation.content,
        }))
      );

      if (data.success) {
        toast.success("Selected Violation added successfully");
        navigate("/company/violation-library");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteViolation = async () => {
    try {
      setIsLoadingDelete(true);

      const selectedViolationsId = selectedViolations.map(
        (violations) => violations.id
      );

      const { data } = await axios.delete(
        `/api/company/violation?violationIds=${selectedViolationsId.join(",")}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedViolations([]);
        getViolations("company");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const uploadCSV = async (file) => {
    try {
      setIsLoadingCSVUpload(true);

      const { data } = await axios.post("/api/media/uploadURL/document", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        putCsvFile(data.s3PostPayload, data.mediaId, file);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingCSVUpload(false);
    }
  };

  function putCsvFile(url, media_id, csvfile) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/csv");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: csvfile,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadCSV(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingCSVUpload(false);
      });
  }

  const ackUploadCSV = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("CSV uploaded successfully.", { duration: 7000 });
        importViolationFromCSV(media_id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCSVUpload(false);
    }
  };

  const importViolationFromCSV = async (media_id) => {
    try {
      setIsLoadingViolationCSVUpload(true);

      const { data } = await axios.post(
        "/api/company/importViolationsFromCSV",
        {
          mediaId: media_id,
        }
      );

      if (data.success) {
        toast.success(data.message);
        getViolations();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 8000 });
    } finally {
      setIsLoadingViolationCSVUpload(false);
    }
  };

  const downloadCSV = async () => {
    try {
      setIsLoadingCSV(true);

      const response = await axios.get(
        `/api/company/downloadSampleViolationCSV`,
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloadedCSV.csv";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Error downloading the CSV file"
      );
    } finally {
      setIsLoadingCSV(false);
    }
  };

  useEffect(() => {
    if (importFromMasterAdmin) getViolations("master_admin");
    else getViolations("company");
  }, [currentPage, rowsPerPage, importFromMasterAdmin]);

  useEffect(() => {
    if (searchQuery.length === 0) {
      if (importFromMasterAdmin) getViolations("master_admin");
      else getViolations("company");
    }

    if (searchQuery.length > 2) {
      if (importFromMasterAdmin) getViolations("master_admin");
      else getViolations("company");
    }
  }, [searchQuery, importFromMasterAdmin]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <input
        type="file"
        className="hidden"
        ref={csvUploadRef}
        onChange={csvhandleChange}
        accept=".csv"
      />
      <Header
        buttonText={user?.company?.companyName}
        title={
          importFromMasterAdmin
            ? "ProCAM Violation Library"
            : "Company Master Violation Library"
        }
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4">
          <div className="grow">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Violation"
              onChange={(e) => {
                onChange(e);
              }}
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full  border-gray border-[1px] py-[0.1rem] rounded-full px-12"
            />
          </div>
          {!importFromMasterAdmin && (
            <>
              <Button
                Icon={csvIcon}
                iconClass="h-[1rem]"
                extraClass="relative text-primary-teal bg-primary-white py-2 px-4 py-[.5rem] text-sm rounded-full border-primary-teal border-[1px]"
                innerText="Upload CSV File"
                onClick={() => {
                  csvUploadRef.current.click();
                }}
                isLoading={isLoadingCSVUpload}
                disabled={isLoadingCSVUpload}
              />
              <Button
                extraClass="relative text-primary-teal bg-primary-white py-2 px-4 py-[.5rem] text-sm rounded-full"
                innerText="Download Sample CSV File"
                onClick={downloadCSV}
                isLoading={isLoadingCSV}
                disabled={isLoadingCSV}
              />

              <Link to="/company/violation-library/add">
                <Button
                  Icon={AddIcon}
                  iconClass=" w-[1rem] h-[1rem]"
                  extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                  innerText="Add New Violation"
                />
              </Link>
            </>
          )}
        </div>

        <div className=" overflow-auto grow flex min-h-[6rem]">
          {isLoading && (
            <div className="flex flex-1 justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={violationLibraryCompanyColumns(
                onSelectHandler,
                selectedViolations,
                onEditHandler,
                violations,
                setSelectedViolations,
                importFromMasterAdmin
              )}
              tableData={violations}
            />
          )}
        </div>

        <div className="flex items-center">
          {importFromMasterAdmin ? (
            <Button
              Icon={ImportIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full mb-4"
              innerText="Import Selected"
              onClick={addViolation}
              spinnerLight={true}
              isLoading={isLoading}
              disabled={isLoading || selectedViolations.length === 0}
            />
          ) : (
            <Button
              Icon={DeleteIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full mb-4"
              innerText="Delete Selected"
              onClick={deleteViolation}
              spinnerLight={true}
              isLoading={isLoadingDelete}
              disabled={isLoadingDelete || selectedViolations.length === 0}
            />
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalViolations}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
