import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Select as MUISelect,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import CustomTooltip from "../../components/Tooltip/Tooltip";
import axios from "../../utils/helpers/axios";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";

import ProfileDashBoardIcon from "../../assets/svg/profileDashBoardIcon.svg";
import ProfileDefaultIcon from "../../assets/svg/profileDefaultIcon.svg";
import InfoIcon from "../../assets/svg/infoIcon.svg";

function Profile() {
  const { user } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [userData, setUserData] = useState({
    communitiesAllowed: [],
  });
  const [communities, setCommunities] = useState([]);
  const [file, setFile] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "15rem",
        borderRadius: "1rem",
        marginTop: "0.5rem",
        height: "fit-content",
      },
    },
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const onChangePassword = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => ({ ...prev, [name]: value }));
  };

  const disableButton = () => {
    const keys = Object.keys(password);

    if (
      keys.every((key) => password[key]) !== keys.some((key) => password[key])
    )
      return true;
  };

  function handleChange(e) {
    setFile(e.target.files[0]);
    setFilePreview(URL.createObjectURL(e.target.files[0]));
  }

  const getUserInfo = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get("/api/auth/profile");

      if (data.success) {
        setUserData(data.userProfile[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCommunities = async () => {
    try {
      const { data } = await axios.get(
        "/api/community/?sortFields=updatedAt&sortOrders=-1&rowsPerPage=1000"
      );

      if (data.success) {
        setCommunities(data.communities);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMediaURL = async () => {
    const { data } = await axios.get(
      `api/media/mediaURL?mediaId=${userData.avatar}`
    );

    if (data.success) {
      setFilePreview(data.media.s3PresignedURL);
    }
  };

  const getCompanyLogoMediaURL = async () => {
    const { data } = await axios.get(
      `api/media/mediaURL?mediaId=${userData.companyDetails.logo}`
    );

    if (data.success) {
      setCompanyLogo(data.media.s3PresignedURL);
    }
  };

  const uploadLogo = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post("/api/media/uploadURL/image", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingUpdate(false);
    }
  };

  function put(url, media_id) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    setIsLoadingUpdate(true);

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingUpdate(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("Avatar uploaded successfully.");
        updateUser(media_id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updateUser = async (media_id) => {
    try {
      setIsLoadingUpdate(true);

      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        role: userData.role,
        communitiesAllowed: userData.communitiesAllowed,
        userId: userData._id,
      };
      if (media_id) payload.avatar = media_id;

      const { data } = await axios.patch("/api/auth/user", payload);

      if (data.success) {
        toast.success("User Updated Successfully");
        navigate("/companyDashboard");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updatePassword = async () => {
    try {
      setIsLoadingUpdate(true);
      const { data } = await axios.patch("/api/auth/changePassword", {
        oldPassword: password.oldPassword,
        newPassword: password.newPassword,
      });

      if (data.success) {
        toast.success(data.message);
        updateUser();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    getCommunities();
  }, []);

  useEffect(() => {
    if (userData.avatar) getMediaURL();
    if (userData.companyDetails?.logo) getCompanyLogoMediaURL();
  }, [userData.avatar, userData.companyDetails?.logo]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="My Profile"
        buttonText={user.company.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      {isLoading && (
        <div className="flex flex-1 justify-center p-[2rem]">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      {!isLoading && (
        <>
          <div
            onClick={() => setToggleSidebar(false)}
            className="flex flex-col grow"
          >
            <div>
              <div className="h-[18rem] w-full rounded-[1rem] py-4 px-6 relative">
                <img
                  src={companyLogo || ProfileDashBoardIcon}
                  alt="Default Dashboard"
                  style={{
                    objectFit: "fit",
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />

                <div className="h-40 w-40 rounded-[2rem] bg-white border-2 border-[#f6f6f6] shadow-lg absolute left-12 bottom-0 translate-y-1/2">
                  <img
                    src={filePreview || ProfileDefaultIcon}
                    alt="Default Profile"
                    style={{
                      objectFit: "fit",
                      width: "100%",
                      height: "100%",
                      borderRadius: "2rem",
                    }}
                  />
                </div>
              </div>

              <div className="ml-[15rem]">
                <div className="text-[2rem] text-primary-teal font-semibold">
                  {userData.firstName + " " + userData.lastName}
                </div>

                <div className="text-primary-gray-light flex gap-[0.5rem]">
                  {userData.role === "company_admin"
                    ? "Company Administrator"
                    : capitalizeInitials(userData.role, "_")}

                  <CustomTooltip
                    title={"User role can be edited by Company Administrator"}
                    placement={"right-start"}
                  >
                    <img src={InfoIcon} alt="" className="cursor-pointer" />
                  </CustomTooltip>
                </div>
              </div>
            </div>

            <div className="mx-[2rem] mt-[2.4rem] flex gap-[1.2rem]">
              <div className="flex flex-col w-1/2 gap-[1rem]">
                <Input
                  label="First Name"
                  placeholder={"Enter your first Name "}
                  type="text"
                  labelClass="text-primary-teal"
                  inputFieldClass="placeholder:text-primary-gray-light"
                  extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                  value={userData.firstName}
                  name="firstName"
                  onChange={onChangeHandler}
                />
                <div className="opacity-[0.5]">
                  <Input
                    label="Email"
                    type="text"
                    labelClass="text-primary-teal"
                    inputFieldClass="placeholder:text-primary-gray-light"
                    extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                    disabled={true}
                    value={userData.email}
                    info={`Email address can only be changed by the Company Administrator.`}
                  />
                </div>
                <Input
                  label="Profile Picture"
                  placeholder="Choose File"
                  type="file"
                  inputFieldClass="placeholder:text-primary-teal font-semibold file:border-none file:bg-transparent file:text-primary-teal file:cursor-pointer"
                  labelClass="text-primary-teal"
                  extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                  onChange={handleChange}
                />
                <div>
                  <div className="text-secondary-gray-light text-sm">
                    Preview Image
                  </div>
                  <div className="w-[7rem] h-[7rem]">
                    <img
                      src={filePreview || ProfileDefaultIcon}
                      className="object-fit w-full h-full rounded-[1rem]"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2 gap-[1rem]">
                <Input
                  label="Last Name"
                  placeholder={"Enter your Last Name "}
                  type="text"
                  labelClass="text-primary-teal"
                  inputFieldClass="placeholder:text-primary-gray-light"
                  extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                  value={userData.lastName}
                  name="lastName"
                  onChange={onChangeHandler}
                />
                <div>
                  <div className="flex gap-[0.5rem]">
                    <span className={"text-primary-teal text-sm font-semibold"}>
                      Assigned Communities
                    </span>
                    <CustomTooltip
                      title={"communities"}
                      placement={"right-start"}
                    >
                      <img src={InfoIcon} alt="" className="cursor-pointer" />
                    </CustomTooltip>
                  </div>
                  <MUISelect
                    sx={{
                      width: "100%",
                      borderRadius: "100rem",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      height: "2rem",
                    }}
                    size="small"
                    id="demo-multiple-checkbox"
                    value={userData.communitiesAllowed}
                    MenuProps={MenuProps}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.includes("*")) return "All";

                      return "Click To View";
                    }}
                  >
                    {userData.communitiesAllowed.includes("*") && (
                      <MenuItem key={"all communities"} value={"*"}>
                        <ListItemText primary="All" />
                      </MenuItem>
                    )}
                    {userData.communitiesAllowed.map((id) => {
                      const communityData = communities.find(
                        (community) => community._id === id
                      );

                      if (!communityData) return <></>;
                      return (
                        <MenuItem key={communityData._id}>
                          <ListItemText primary={communityData.letterName} />
                        </MenuItem>
                      );
                    })}
                  </MUISelect>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="text-primary-gray-light text-2xl pl-[2rem] mb-[-1rem] mt-[1rem]">
                Edit Password
              </div>
              <div className="mx-[2rem] mt-[2.4rem] flex gap-[1.2rem]">
                <div className="flex flex-col w-1/2 gap-[1rem]">
                  <Input
                    label="Current Password"
                    placeholder="Enter Current Password"
                    type="text"
                    labelClass="text-primary-teal"
                    inputFieldClass="placeholder:text-primary-gray-light"
                    extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                    onChange={onChangePassword}
                    name="oldPassword"
                  />
                  <Input
                    label="New Password"
                    placeholder="Enter New Password"
                    type="text"
                    labelClass="text-primary-teal"
                    inputFieldClass="placeholder:text-primary-gray-light"
                    extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                    onChange={onChangePassword}
                    name="newPassword"
                    error={
                      password.confirmPassword !== password.newPassword
                        ? "Confirm Password and New Password must be same"
                        : ""
                    }
                  />
                  <Input
                    label="Confirm Password"
                    placeholder="Confirm New Password"
                    type="text"
                    labelClass="text-primary-teal"
                    inputFieldClass="placeholder:text-primary-gray-light"
                    extraClass="border-gray border-[1px] rounded-full px-[1rem]"
                    onChange={onChangePassword}
                    name="confirmPassword"
                    error={
                      password.confirmPassword !== password.newPassword
                        ? "Confirm Password and New Password must be same"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mx-[2rem] mt-auto py-[1rem] pb-24 flex gap-[1rem] justify-end">
            <Button
              iconClass=" w-[20px] h-[20px]"
              extraClass="text-primary-red bg-primary-white border-primary-red border-[0.5px] py-1 px-4 text-md rounded-full"
              innerText="Cancel"
              onClick={() => navigate("/companyDashboard")}
            />
            <Button
              iconClass=" w-[20px] h-[20px]"
              extraClass="text-primary-white bg-primary-teal py-1 px-4 text-md rounded-full"
              innerText="Save Changes"
              onClick={() => {
                if (file) uploadLogo();
                else if (
                  Object.keys(password).every((key) => password[key].length)
                )
                  updatePassword();
                else updateUser();
              }}
              spinnerLight={true}
              isLoading={isLoadingUpdate}
              disabled={isLoadingUpdate || disableButton()}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
