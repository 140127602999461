import ErrorIcon from "../../assets/svg/errorIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import CustomTooltip from "../Tooltip/Tooltip";

const Input = ({
  placeholder,
  handleIconClick,
  Icon,
  type,
  name,
  id,
  error,
  label,
  extraClass,
  inputFieldClass,
  iconClass,
  labelClass,
  message,
  required,
  info,
  outerClass,
  customText,
  customTextClass,
  customTextOnClick = () => {},
  ...rest
}) => {
  return (
    <div className={`${outerClass}`}>
      <div
        className={`flex gap-1 items-center font-semibold text-sm ${labelClass}`}
      >
        <span>
          {label}
          {required ? " *" : ""}
        </span>
        {info && (
          <CustomTooltip title={info} placement="right">
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        )}
      </div>
      <div
        className={` relative flex items-center shadow-inputShadow hover:shadow-inputShadowActive ${extraClass}`}
      >
        <div className={"flex justify-between w-full items-center"}>
          <input
            className={`bg-transparent focus:outline-none w-full py-[.4rem] text-sm ${inputFieldClass} ${
              error ? "border-primary-red" : ""
            }`}
            type={type}
            placeholder={placeholder}
            name={name}
            id={id}
            {...rest}
          />
          {customText && (
            <span
              onClick={customTextOnClick}
              className={`text-sm cursor-pointer text-primary-teal font-semibold text-nowrap px-4 ${customTextClass}`}
            >
              {customText}
            </span>
          )}
        </div>
        {Icon && (
          <Icon
            onClick={handleIconClick}
            className={`top-[50%] -translate-y-[50%] ${iconClass}`}
          />
        )}
      </div>
      {message && (
        <p className=" absolute pl-4 font-normal text-xs text-secondary-gray">
          {message}
        </p>
      )}
      {error && (
        <p className="flex items-center space-x-2 mt-1.5 pl-1 font-normal text-xs text-primary-red">
          <span>
            <ErrorIcon />
          </span>{" "}
          <div>{error}</div>
        </p>
      )}
    </div>
  );
};

export default Input;
