import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import HelpHeader from "../../components/Help/HelpHeader";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

const faqs = [
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
  {
    title: "Getting Started",
    articles: [
      "Sign Up for ProCam INSPECT",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
      "Download the Mobile App",
      "Log In to Your Account",
      "Log In to Your Account",
      "Navigate the Dashboard",
      "Set Up Your Profile",
    ],
  },
];

export default function Help() {
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [openModal, setOpenModal] = useState(false);
  const [selectedFaqArticles, setSelectedFqaArticles] = useState([]);

  return (
    <div className="w-full relative">
      <HelpHeader />
      <div
        onClick={() => setToggleSidebar(false)}
        className="grid grid-cols-3 gap-y-[2rem] pl-[7rem] mt-6"
      >
        {faqs.map((faq) => (
          <div className="flex flex-col gap-2">
            <h3 className="text-[1rem] text-secondary-gray">{faq.title}</h3>
            <div className="pl-8 text-[0.8rem] text-primary-teal">
              <ul style={{ listStyleType: "disc" }}>
                {faq.articles.slice(0, 5).map((article) => (
                  <li>
                    <div
                      onClick={() => navigate("/company/help/article")}
                      className="cursor-pointer"
                    >
                      {article}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {faq.articles.length > 5 && (
              <div
                className="text-[0.8rem] text-primary-teal underline cursor-pointer"
                onClick={() => {
                  setSelectedFqaArticles(faq.articles);
                  setOpenModal(true);
                }}
              >
                View All {faq.articles.length} Articles
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl py-4 px-12 text-primary-teal">
          <ul
            className="grid grid-cols-3 gap-[1rem]"
            style={{ listStyleType: "disc" }}
          >
            {selectedFaqArticles.map((article) => (
              <li>
                <div
                  onClick={() => navigate("/company/help/article")}
                  className="cursor-pointer"
                >
                  {article}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
}
