import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

import {
  Select as MUISelect,
  MenuItem,
  ListItemText,
  Checkbox,
  OutlinedInput,
} from "@mui/material";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import { ToggleSidebarContext } from "../Layout/Layout";
import axios from "../../utils/helpers/axios";
import Modal from "react-responsive-modal";

export default function AddAttorney() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const { user } = useSelector((state) => state.auth);

  const [attorneyData, setAttorneyData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    communitiesAllowed: [],
  });
  const [communities, setCommunities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [open, setOpen] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "15rem",
        borderRadius: "1rem",
        marginTop: "0.5rem",
      },
    },
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setAttorneyData((prev) => ({ ...prev, [name]: value }));
  };

  const getCommunities = async () => {
    try {
      const { data } = await axios.get(
        "/api/community/?sortFields=updatedAt&sortOrders=-1"
      );
      if (data.success) setCommunities(data.communities);
    } catch (error) {
      console.error(error);
    }
  };

  const getAttorneyById = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/company/attorneys?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const attorney = data.attorneys[0];

        setAttorneyData(
          Object.keys(attorneyData).reduce((acc, key) => {
            if (key in attorney) acc[key] = attorney[key];

            return acc;
          }, {})
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addAttorney = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post(
        "/api/auth/onboard/attorney",
        attorneyData
      );

      if (data.success) {
        toast.success(data.message || "Attorney added successfully");
        navigate("/company/attornies");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updateAttorney = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.patch("/api/auth/attorney", {
        attorneyId: id,
        ...attorneyData,
      });

      if (data.success) {
        toast.success(data.message);
        navigate("/company/attornies");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const deleteAttorney = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await axios.delete(
        `/api/auth/attorney?attorneyId=${id}`
      );

      if (response?.data?.success) {
        toast.success("User Deleted Successfully");

        setIsLoading(false);
        navigate("/company/attornies");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    if (id) getAttorneyById();
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title={id ? "Edit Attorney" : "Add Attorney"}
        buttonText={user.company.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        {isLoading && (
          <div className="flex justify-center items-center w-full mt-2">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading && (
          <div className="flex flex-col gap-4 grow">
            <div className="flex gap-4 ">
              <Input
                label="Attorney First Name"
                labelClass="text-primary-teal"
                type="text"
                required={true}
                placeholder="Enter Attorney First Name"
                inputFieldClass="px-4 w-full"
                value={attorneyData.firstName}
                onChange={onChangeHandler}
                name={"firstName"}
                outerClass="w-full"
                info={
                  "When an attorney is assigned to to a community, they will be sent a system generated email when a property is escalated to attorney level."
                }
                extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
              />
              <Input
                label="Attorney Last Name"
                labelClass="text-primary-teal"
                type="text"
                required={true}
                placeholder="Enter Attorney Last Name"
                inputFieldClass="px-4 w-full"
                value={attorneyData.lastName}
                onChange={onChangeHandler}
                name={"lastName"}
                outerClass="w-full"
                extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
              />
              <Input
                label="Attorney Email ID"
                labelClass="text-primary-teal"
                type="text"
                placeholder="Enter Attorney Email"
                inputFieldClass="px-4 w-full"
                value={attorneyData.email}
                onChange={onChangeHandler}
                name={"email"}
                outerClass="w-full"
                extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
              />
            </div>

            <div className="w-1/3">
              <div className="flex gap-1">
                <span className={"text-primary-teal text-sm font-semibold"}>
                  Assigned Communities *
                </span>
              </div>
              <MUISelect
                sx={{
                  width: "100%",
                  borderRadius: "100rem",
                  fontFamily: "inherit",
                  fontSize: "14px",
                  height: "2rem",
                }}
                size="small"
                id="demo-multiple-checkbox"
                multiple
                MenuProps={MenuProps}
                value={attorneyData.communitiesAllowed}
                onChange={(e) => {
                  if (e.target.value.includes("*"))
                    return setAttorneyData((prev) => ({
                      ...prev,
                      communitiesAllowed: ["*"],
                    }));

                  setAttorneyData((prev) => ({
                    ...prev,
                    communitiesAllowed: e.target.value,
                  }));
                }}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.includes("*")) return "All";

                  return selected
                    .map(
                      (cummunityID) =>
                        communities.find(
                          (community) => community._id === cummunityID
                        )?.letterName
                    )
                    .join(", ");
                }}
              >
                <MenuItem key={"all communities"} value={"*"}>
                  <Checkbox
                    checked={attorneyData.communitiesAllowed.includes("*")}
                  />
                  <ListItemText primary="All" />
                </MenuItem>
                {communities.map((community) => (
                  <MenuItem
                    key={community._id}
                    value={community._id}
                    disabled={attorneyData.communitiesAllowed.includes("*")}
                  >
                    <Checkbox
                      checked={
                        attorneyData.communitiesAllowed.findIndex(
                          (item) => item === community._id
                        ) > -1
                      }
                    />
                    <ListItemText primary={community.legalName} />
                  </MenuItem>
                ))}
              </MUISelect>
              {attorneyData.communitiesAllowed.length === 0 && (
                <p className="text-primary-red text-[0.7rem]">
                  * Select atleast one Community
                </p>
              )}
            </div>

            <div className="flex justify-between mt-auto mb-2">
              <div>
                {id && (
                  <Button
                    type="submit"
                    innerText="Delete Attorney"
                    extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-sm uppercase text-primary-red"
                    onClick={() => setOpen(true)}
                  />
                )}
              </div>
              <div className="flex gap-4">
                <Button
                  type="submit"
                  innerText="Cancel"
                  extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-sm uppercase text-primary-red"
                  onClick={() => navigate("/company/attornies")}
                  isLoadingUpdate={isLoadingDelete}
                  disabled={isLoadingDelete}
                />

                {id ? (
                  <Button
                    type="submit"
                    innerText="Save Changes"
                    spinnerLight={true}
                    extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
                    isLoading={isLoadingUpdate}
                    disabled={
                      isLoadingUpdate ||
                      !attorneyData.firstName ||
                      !attorneyData.lastName ||
                      !attorneyData.email ||
                      attorneyData.communitiesAllowed.length === 0
                    }
                    onClick={updateAttorney}
                  />
                ) : (
                  <Button
                    type="submit"
                    innerText="Add Attorney"
                    spinnerLight={true}
                    extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
                    isLoading={isLoadingUpdate}
                    disabled={
                      isLoadingUpdate ||
                      !attorneyData.firstName ||
                      !attorneyData.lastName ||
                      !attorneyData.email ||
                      attorneyData.communitiesAllowed.length === 0
                    }
                    onClick={addAttorney}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-4 flex flex-col">
          <div className="text-primary-teal text-lg">
            Are you sure you wish to delete this Attorney?
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setOpen(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full py-2 px-4 text-primary-teal"
            />
            <Button
              onClick={() => {
                deleteAttorney();
              }}
              isLoading={isLoadingDelete}
              disabled={isLoadingDelete}
              innerText="Delete Attorney"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
