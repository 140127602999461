import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import PropertyInfoCard from "../../components/PropertyInfoCard/PropertyInfoCard";
import Header from "../../components/Header/Header";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Select from "../../components/Input/SelectInput";

import NumberSortIcon from "../../assets/svg/numberSortIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function CommunityProperties() {
  const auth = useSelector((state) => state.auth);
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortFields, setSortFields] = useState({
    field: "updatedAt",
    value: -1,
  });
  const [totalProperties, setTotalProperties] = useState();
  const [properties, setProperties] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState({});
  const [streets, setStreets] = useState([]);
  const [street, setStreet] = useState({});
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSelectStreet = (id, data) => {
    setStreet(data);
    setFilterFields({
      field: "streetName",
      value: data._id,
    });
  };

  const getMailingAddress = (property) => {
    const ownerData = property.propertyOwnerShip[0];
    const alternateAddressData = ownerData?.alternateAddress;

    if (ownerData.isMailingAddressDifferent) {
      const mailingData = [
        alternateAddressData.firstOwnerFirstName,
        alternateAddressData.firstOwnerLastName,
        alternateAddressData.streetNumber,
        alternateAddressData.streetName,
        alternateAddressData.city,
        alternateAddressData.state,
        alternateAddressData.zip,
      ];

      const check = mailingData.every((item) => item);

      if (check)
        return `${alternateAddressData.firstOwnerFirstName} ${
          alternateAddressData.firstOwnerLastName
        }  ${
          alternateAddressData.secondOwnerFirstName
            ? `& ${alternateAddressData.secondOwnerFirstName} ${alternateAddressData.secondOwnerLastName}`
            : ""
        } ${
          ", " +
          alternateAddressData.streetNumber +
          " " +
          alternateAddressData.streetName
        }
      ${", " + alternateAddressData.city}
      ${", " + alternateAddressData.state}
      ${", " + alternateAddressData.zip}`;
    } else {
      const mailingData = [
        ownerData.firstOwnerFirstName,
        ownerData.firstOwnerLastName,
        property.streetNumber,
        property.streetName,
        property.city,
        property.state,
        property.zip,
      ];
      const check = mailingData.every((item) => item);

      if (check)
        return `${ownerData.firstOwnerFirstName} ${
          ownerData.firstOwnerLastName
        } ${
          ownerData.secondOwnerFirstName
            ? `& ${ownerData.secondOwnerFirstName} ${ownerData.secondOwnerLastName} `
            : ""
        } ${", " + property.streetNumber + " " + property.streetName}
      ${", " + property.city}
      ${", " + property.state}
      ${", " + property.zip} `;
    }
  };

  const getPropertyName = (property) => {
    const propertyOwnerShip = property.propertyOwnerShip[0];

    return `${propertyOwnerShip.firstOwnerFirstName} ${
      propertyOwnerShip.firstOwnerLastName
    } ${
      propertyOwnerShip.secondOwnerFirstName
        ? "& " + propertyOwnerShip.secondOwnerFirstName
        : ""
    } ${
      propertyOwnerShip.secondOwnerLastName
        ? propertyOwnerShip.secondOwnerLastName
        : ""
    }`;
  };

  const getProperties = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property?${
          community._id ? "communityId=" + community._id : ""
        }&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=${
          sortFields.field
        }&sortOrders=${sortFields.value}&${
          filterFields.field
            ? "filterFields=" +
              filterFields.field +
              "&filterValues=" +
              filterFields.value +
              "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setTotalProperties(data.matchedPropertiesCount);
        setProperties(data.properties);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCommunities = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/community?sortFields=updatedAt&sortOrders=-1"
      );

      if (data.success) {
        setCommunities(data.communities);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStreet = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/streets?communityId=${community._id}`
      );

      if (data.success) {
        setStreets(data.streets);
      }
    } catch (error) {
      console.error();
    }
  };

  useEffect(() => {
    if (community._id) getStreet();
  }, [community._id]);

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    getProperties();
  }, [
    rowsPerPage,
    currentPage,
    sortFields.value,
    filterFields.value,
    community._id,
  ]);

  useEffect(() => {
    if (searchQuery.length === 0) getProperties();
    if (searchQuery.length > 2) getProperties();
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <Header
        title="Properties"
        buttonText={auth.user.company.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4 items-center justify-between">
          <div className="grow">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Property by Address / Street / Owner’s Name"
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-12"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="min-w-fit flex items-center gap-4 cursor-pointer">
            <NumberSortIcon
              onClick={() =>
                setSortFields({
                  field: "streetNumber",
                  value: sortFields.value === -1 ? 1 : -1,
                })
              }
            />

            <Link
              to={
                community._id &&
                `/community/${community._id}/property/add?companyAdmin=true`
              }
            >
              <Button
                Icon={AddIcon}
                iconClass=" w-[15px] h-[15px]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add Individual Property"
                disabled={!community._id}
              />
            </Link>
          </div>
        </div>

        <div className="flex item-center gap-4">
          <Select
            iconClass="absolute top-[22%] left-2 text-secondary-gray"
            placeholder="Select Communitiy"
            inputFieldClass="placeholder:text-primary-gray-light"
            outerClass="w-full"
            dropdownData={communities}
            id="community"
            fieldName="letterName"
            value={community?.letterName}
            handleChange={(id, data) => setCommunity(data)}
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-4"
          />

          <Select
            iconClass="absolute top-[22%] left-2 text-secondary-gray"
            placeholder="Select Street"
            inputFieldClass="placeholder:text-primary-gray-light"
            outerClass="w-full"
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-4"
            error={!community._id && "*Select Community"}
            disabled={!community._id}
            dropdownData={streets}
            fieldName={"_id"}
            handleChange={onSelectStreet}
            value={street._id}
          />
        </div>

        <div className="overflow-auto min-h-[6rem] px-4 flex flex-col">
          {isLoading && (
            <div className="flex justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading &&
            properties.map((property) => {
              const propertyOwnerShip = property.propertyOwnerShip[0];

              return (
                <div
                  onClick={() =>
                    navigate(
                      `/community/${property.community}/property-details/${property._id}?companyAdmin=true`
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <PropertyInfoCard
                    key={property._id}
                    btnText={`${property.streetNumber} 
                      ${property.streetName} 
                      ${
                        property.addressLine2
                          ? ", " + property.addressLine2
                          : ""
                      }`}
                    name={getPropertyName(property)}
                    phone={propertyOwnerShip.mobileNumber}
                    email={propertyOwnerShip.email}
                    mailingAddress={getMailingAddress(property)}
                  />
                </div>
              );
            })}
          {!isLoading && properties.length === 0 && (
            <div className="flex justify-center text-primary-gray">
              No properties found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalProperties}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
