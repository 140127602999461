import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

import ImportIcon from "../../assets/svg/importIcon";
import TextArea from "../Input/TextArea";

export default function AddViolationCommunity() {
  const { id, vid } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [violations, setViolations] = useState({
    title: "",
    content: "",
  });
  const [community, setCommunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setViolations((prev) => ({ ...prev, [name]: value }));
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getViolationsId = async () => {
    try {
      const { data } = await axios.get(
        `/api/company/violation/community?communityId=${id}&pageNum=1&rowsPerPage=30&sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${vid}`
      );

      if (data.success) {
        const violation = data.violations[0];

        setViolations({
          title: violation.title,
          content: violation.content,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addViolation = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/community/violation", {
        communityId: id,
        violations: [violations],
      });

      if (data.success) {
        toast.success("Violation added successfully");
        setTimeout(() => {
          navigate(`/community/violation-library/${id}`);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const editViolation = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.patch("/api/community/violation", {
        communityId: id,
        violationId: vid,
        updateFields: violations,
      });

      if (data.success) {
        toast.success(data.message);
        navigate(`/community/violation-library/${id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (vid) getViolationsId();
  }, [vid]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title={vid ? "Edit Violation" : "Add Violation"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full">
          {!vid && (
            <div className="flex items-center gap-4">
              <Button
                Icon={ImportIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-[0.75rem] text-primary-white w-fit"
                innerText="Import from the Master Violation Library"
                onClick={() => {
                  navigate(
                    `/community/violation-library/${id}?importFromCompanyAdmin=true`
                  );
                }}
              />
              <span className="text-primary-gray-light text-[0.8rem]">
                Type a new Violation or save some time by selecting from Master
                Violation Library. All violations are editable after import.
              </span>
            </div>
          )}
        </div>
        <div className="flex gap-4 grow">
          <TextArea
            label="Violation Title"
            placeholder="Enter Violation Title"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-6"
            required={true}
            onChange={onChange}
            name={"title"}
            outerClass="w-full"
            extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            value={violations.title}
            info={`Violation Title is the headline text that will show in the application to quickly identify the violation. This text will not appear in the letter.  We recommend keeping it short. Examples: "Garbage Cans" or "Dead Turfgrass`}
          />
          <TextArea
            label="Violation"
            labelClass="text-primary-teal"
            placeholder="Enter Violation Content"
            inputFieldClass="px-6"
            required={true}
            type="text"
            name={"content"}
            onChange={onChange}
            outerClass="w-full"
            extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            value={violations.content}
            info={
              "Violation Text is the actual violation language that will appear in letters sent to owners."
            }
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button
            type="submit"
            innerText="Cancel"
            extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white px-4 text-sm py-2 rounded-full text-primary-red"
            onClick={() => navigate(`/community/violation-library/${id}`)}
          />
          {vid ? (
            <Button
              type="submit"
              innerText="Save Changes"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-sm px-4 text-primary-white"
              isLoading={isLoading}
              disabled={isLoading || !violations.title || !violations.content}
              onClick={editViolation}
              spinnerLight={true}
            />
          ) : (
            <Button
              type="submit"
              innerText="Add violation"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 text-sm px-4 text-primary-white"
              isLoading={isLoading}
              disabled={isLoading || !violations.title || !violations.content}
              onClick={addViolation}
              spinnerLight={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
