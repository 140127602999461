import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/adminAxios";
import { api } from "../../utils/helpers/url";
import CKEditorComponent from "../CKEditor/CKEditor";
import Button from "../Button/Button";
import MarginModel from "../MarginModel/MarginModel";
import CustomTooltip from "../Tooltip/Tooltip";
import BasicTooltip from "../Tooltip/EditorTooltip";
import { convertFileImageIntoBase64 } from "../../utils/helpers/filetoBase64";

import MarginIcon from "../../assets/svg/marginIcon.svg";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import UploadFromDekstopIcon from "../../assets/svg/uploadFromDekstopIcon.svg";

export default function AddLetterTemplate() {
  const auth = useSelector((store) => store.adminAuth);
  const previewBtnRef = useRef("");
  const imageUploadRef = useRef("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [letterTemplate, setLetterTemplate] = useState({
    title: "",
  });
  const [filePreview, setFilePreview] = useState("");
  const [letterTags, setLetterTags] = useState([]);
  const [tagSearch, setTagSearch] = useState("");
  const [margins, setMargins] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [showMarginModel, setShowMarginModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;

    setLetterTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMargins({
      ...margins,
      [name]: Number(value),
    });
  };

  async function handleChange(e) {
    if (e.target.files[0]) {
      const data = await convertFileImageIntoBase64(e.target.files[0]);
      setFilePreview(data);
    }
  }

  const onSelectTag = (data) => {
    navigator.clipboard.writeText(data.tag);

    toast.success(`${data.name} copied to clipboard`);
  };

  const getLetterTemplate = async () => {
    try {
      const { data } = await axios.get(
        `/admin/getLetterTemplate?pageNum=1&rowsPerPage=30&sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const letterTemplate = data.letterTemplates[0];

        setLetterTemplate({
          title: letterTemplate.title,
          margins: letterTemplate.margins,
        });
        setMargins(letterTemplate.margins);
        setValue(letterTemplate.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLetterTags = async () => {
    try {
      const { data } = await axios.get("/admin/getLetterTags");

      if (data.success) {
        setLetterTags(data.letterTags);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addLetterTemplate = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/admin/addLetterTemplate", [
        {
          title: letterTemplate.title,
          content: value,
          margins,
        },
      ]);

      if (data.success) {
        toast.success(data.message);
        navigate("/letter-library");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const editLetterTemplate = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.patch("/admin/updateLetterTemplate", {
        letterTemplateId: id,
        updateFields: {
          title: letterTemplate.title,
          content: value,
          margins,
        },
      });

      if (data.success) {
        toast.success(data.message);
        navigate("/letter-library");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLetterTags();
  }, []);

  useEffect(() => {
    if (id) getLetterTemplate();
  }, [id]);

  return (
    <div className="w-full h-full">
      <input
        type="file"
        className="hidden"
        ref={imageUploadRef}
        onChange={handleChange}
      />
      <Header title={id ? "Edit Letter Template" : "Add Letter Template"} />

      <form
        style={{ display: "none" }}
        method="post"
        target="_blank"
        action={`${api}/admin/letterPreview?token=${auth.token}`}
      >
        <input type="hidden" name="letterData" value={value} />
        <input type="hidden" name="marginTop" value={margins?.top} />
        <input type="hidden" name="marginBottom" value={margins?.bottom} />
        <input type="hidden" name="marginLeft" value={margins?.left} />
        <input type="hidden" name="marginRight" value={margins?.right} />
        <input type="submit" ref={previewBtnRef} />
      </form>

      <div className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4 ">
        <div className="flex gap-4">
          <Input
            label="Template Name"
            placeholder="Enter Template Name"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-4"
            required={true}
            outerClass="w-full"
            extraClass="border-primary-gray border-[1px] rounded-full"
            onChange={(e) => {
              onChange(e);
            }}
            value={letterTemplate.title}
            name="title"
          />
          <div className="w-full">
            <div className="flex gap-1">
              <span className={"text-primary-teal text-sm font-semibold"}>
                Tags
              </span>
              <CustomTooltip
                title={`Tags will automatically tell the system to pull the data associated with the tag and place it in the letter.
To use: 
1) Place your cursor in the desired area of the template. 
2) Select the tag you wish to insert from the dropdown. Keep and eye out for the message at the bottom of the screen which says that the tag has been copied.
3) Either right-click on your mouse and  select "paste" or press Ctrl + V on your keyboard.
4) Tag will then be inserted. Be sure not to modify the tag in any way.`}
                placement={"right"}
              >
                <img src={InfoIcon} alt="" className="cursor-pointer" />
              </CustomTooltip>
            </div>
            <Autocomplete
              onChange={(e, newValue) => {
                if (!newValue) return;

                const LetterTagSelected = letterTags.find(
                  (letterTag) => letterTag.name === newValue
                );
                onSelectTag(LetterTagSelected);
              }}
              inputValue={tagSearch}
              onInputChange={(e, newInputValue) => {
                setTagSearch(newInputValue);
              }}
              options={letterTags.map((lettertag) => lettertag.name)}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField placeholder="Select tags" {...params} />
              )}
              size="small"
            />
          </div>
        </div>

        <div className="relative grow">
          <div
            className="absolute right-0 p-[0.3rem]"
            style={{ zIndex: "100" }}
          >
            <div className="flex items-center">
              <BasicTooltip
                Icon={
                  <img
                    src={UploadFromDekstopIcon}
                    alt=""
                    onClick={() => {
                      imageUploadRef.current.click();
                    }}
                    className={`"cursor-pointer"`}
                  />
                }
                title="Upload image from computer"
              />
              <BasicTooltip
                Icon={
                  <img
                    src={MarginIcon}
                    alt=""
                    onClick={() => setShowMarginModel(!showMarginModel)}
                    className="cursor-pointer"
                  />
                }
                title="Adjust Margin"
              />
            </div>

            {showMarginModel && (
              <MarginModel
                margins={margins}
                onChange={handleInputChange}
                setShowMarginModel={setShowMarginModel}
                setMargins={setMargins}
                letterTemplate={letterTemplate}
                edit={id}
              />
            )}
          </div>
          <CKEditorComponent
            setValue={setValue}
            value={value}
            filePreview={filePreview}
          />
        </div>

        <div className="flex justify-between">
          <div className="flex gap-x-4">
            <Button
              innerText={`Preview`}
              extraClass={`shadow-button bg-primary-white border-[1px] border-primary-teal rounded-full py-2 px-4 text-sm captilize text-primary-teal`}
              onClick={() => {
                previewBtnRef.current.click();
              }}
              disabled={isLoading}
            />
            <Button
              type="submit"
              innerText="Cancel"
              extraClass="shadow-button bg-primary-whitepx-4 border-[1px] border-primary-red rounded-full py-2 px-4 text-sm captilize text-primary-red"
              onClick={() => navigate("/letter-library")}
            />
          </div>
          {id ? (
            <Button
              type="submit"
              innerText="Save Changes"
              extraClass="shadow-button bg-primary-teal px-4 border-[1px] border-primary-teal rounded-full py-2 text-sm xl:text-sm captilize text-primary-white"
              onClick={editLetterTemplate}
              isLoading={isLoading}
              spinnerLight={true}
            />
          ) : (
            <Button
              type="submit"
              innerText="Save Template"
              extraClass="shadow-button bg-primary-teal px-4 border-[1px] border-primary-teal rounded-full py-2 text-sm captilize text-primary-white"
              onClick={addLetterTemplate}
              isLoading={isLoading}
              spinnerLight={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
