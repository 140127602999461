import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { violationLibraryColumns } from "../../constants/TableColumns/Columns";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../utils/helpers/adminAxios";

import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import csvIcon from "../../assets/svg/csvIcon";
import DeleteIcon from "../../assets/svg/deleteIcon";

export default function ViolationLibrary() {
  const csvUploadRef = useRef("");
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [violations, setViolations] = useState([]);
  const [totalViolations, setTotalViolations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingCSVUpload, setIsLoadingCSVUpload] = useState(false);
  const [isLoadingViolationCSVUpload, setIsLoadingViolationCSVUpload] =
    useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const onSelectHandler = (e, row) => {
    if (e.target.checked) {
      setSelectedViolations([
        ...selectedViolations,
        { id: row._id, title: row.title, content: row.content },
      ]);
    } else {
      setSelectedViolations(
        selectedViolations.filter((item) => item.id !== row._id)
      );
    }
  };

  const onEditHandler = (row) => {
    navigate(`/violation-library/edit/${row._id}`);
  };

  function csvhandleChange(e) {
    if (e.target.files[0]) {
      uploadCSV(e.target.files[0]);
      e.target.value = null;
    }
  }

  const getViolations = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/admin/getViolation?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=title&sortOrders=1&${
          searchQuery ? "searchQuery=" + searchQuery : ""
        }`
      );

      if (data.success) {
        setViolations(data.violations);
        setTotalViolations(data.matchedViolationCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteViolations = async () => {
    try {
      setIsLoadingDelete(true);
      const selectedViolationsId = selectedViolations.map(
        (violation) => violation.id
      );

      const { data } = await axios.delete(
        `/admin/deleteViolation?violationIds=${selectedViolationsId.join(",")}`
      );

      if (data.success) {
        toast.success(data.message);
        setSelectedViolations([]);
        getViolations();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const uploadCSV = async (file) => {
    try {
      setIsLoadingCSVUpload(true);

      const { data } = await axios.post("/admin/uploadURL/document", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        putCsvFile(data.s3PostPayload, data.mediaId, file);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingCSVUpload(false);
    }
  };

  function putCsvFile(url, media_id, csvfile) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/csv");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: csvfile,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadCSV(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingCSVUpload(false);
      });
  }

  const ackUploadCSV = async (media_id) => {
    try {
      const { data } = await axios.post(`/admin/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("CSV uploaded successfully.", { duration: 7000 });
        importViolationFromCSV(media_id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCSVUpload(false);
    }
  };

  const importViolationFromCSV = async (media_id) => {
    try {
      setIsLoadingViolationCSVUpload(true);

      const { data } = await axios.post("/admin/importViolationsFromCSV", {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success(data.message);
        getViolations();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 8000 });
    } finally {
      setIsLoadingViolationCSVUpload(false);
    }
  };

  const downloadCSV = async () => {
    try {
      setIsLoadingCSV(true);

      const response = await axios.get(`/admin/downloadSampleViolationCSV`, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloadedCSV.csv";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Error downloading the CSV file"
      );
    } finally {
      setIsLoadingCSV(false);
    }
  };

  useEffect(() => {
    getViolations();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getViolations();
    if (searchQuery.length > 2) getViolations();
  }, [searchQuery]);

  return (
    <div className="w-full">
      <input
        type="file"
        className="hidden"
        ref={csvUploadRef}
        onChange={csvhandleChange}
        accept=".csv"
      />
      <Header title="ProCAM Violation Library" buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex gap-4">
          <div className="w-full">
            <Input
              iconClass="absolute left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search Violation"
              onChange={(e) => {
                onChange(e);
              }}
              inputFieldClass="placeholder:text-primary-gray-light"
              extraClass="relative w-full py-[0.1rem] rounded-full px-12 border-gray border-[1px]"
            />
          </div>
          <div className="min-w-fit flex gap-4">
            <Button
              Icon={csvIcon}
              iconClass="h-[1rem]"
              extraClass="relative text-primary-teal bg-primary-white py-2 px-4 py-[.5rem] text-sm rounded-full border-primary-teal border-[1px]"
              innerText="Upload CSV File"
              onClick={() => {
                csvUploadRef.current.click();
              }}
              isLoading={isLoadingCSVUpload}
              disabled={isLoadingCSVUpload}
            />
            <Button
              extraClass="relative text-primary-teal bg-primary-white py-2 px-4 py-[.5rem] text-sm rounded-full"
              innerText="Download Sample CSV File"
              onClick={downloadCSV}
              isLoading={isLoadingCSV}
              disabled={isLoadingCSV}
            />

            <Link to="/violation-library/add">
              <Button
                Icon={AddIcon}
                iconClass="w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-sm rounded-full"
                innerText="Add New Violation"
              />
            </Link>
          </div>
        </div>

        <div className="overflow-auto grow px-2 min-h-[6rem] flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={violationLibraryColumns(
                onSelectHandler,
                selectedViolations,
                onEditHandler,
                violations,
                setSelectedViolations
              )}
              tableData={violations}
            />
          )}
        </div>
        <div>
          <Button
            Icon={DeleteIcon}
            iconClass="w-[0.8rem] h-[0.8rem]"
            extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-sm rounded-full "
            innerText="Delete Selected"
            onClick={deleteViolations}
            disabled={isLoadingDelete || selectedViolations.length === 0}
            spinnerLight={true}
            isLoading={isLoadingDelete}
          />
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalViolations}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
