import { useContext, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import Select from "../../components/Input/SelectInput";
import axiosInstance from "../../utils/helpers/adminAxios";
import Wrapper from "./Wrapper";

import securityIcon from "../../assets/svg/securityIcon";

export default function SecurityPermission() {
  const { id } = useParams();
  const [searchParam] = useSearchParams();
  const masterUser = searchParam.get("masterUser");
  const edit = searchParam.get("edit");
  const location = useLocation();
  const userData = location?.state?.userData;

  const navigate = useNavigate();

  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const [allActivities, setAllActivities] = useState([]);
  const [allActivitiesWithSuffix, setAllActivitiesWithSuffix] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [addActivity, setAddActivity] = useState([]);
  const [removeActivity, setRemoveActivity] = useState([]);
  const [checkedActivities, setCheckedActivities] = useState([]);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAuthActivityGT = async () => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get("/admin/getAuthActivityGT");

      if (data.success) {
        const result = [];

        for (const key in data.authGT.authorizationActivities) {
          const item = data.authGT.authorizationActivities[key];
          const newItem = {
            id: key,
            ...item,
          };
          result.push(newItem);
        }

        setAllActivities(result);
        setAllActivitiesWithSuffix(data.authGT.activitiesWithOps);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/admin/getUsers?pageNum=1&rowsPerPage=5&sortFields=updatedAt&sortOrders=-1&searchQuery=${userEmail}`
      );

      setUsers(
        data.users.map((user) => ({
          ...user,
          viewField: `${user.firstName} ${user.lastName} (${user.email})`,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getUserFromId = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(
        `/admin/getUsers?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setUserEmail(data.users[0].email);
        setSelectedUser(data.users[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addUserFromMaster = async () => {
    try {
      const { data } = await axiosInstance.post("/admin/oboardUser", userData);

      if (data.success) {
        updatePermissions(
          "User Onboarded Successfully with Selected Permissions.",
          "/user"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePermissions = async (msg = "", navigateTo = "") => {
    try {
      setIsLoadingUpdate(true);
      const { data } = await axiosInstance.post(
        "/admin/manageUserAuthorizationActivity",
        {
          email: selectedUser.email,
          add_activities: addActivity.filter(
            (item) => !selectedUser.authorizedActivities.includes(item)
          ),
          remove_activities: removeActivity.filter((item) =>
            selectedUser.authorizedActivities.includes(item)
          ),
        }
      );

      if (data.success) {
        toast.success(msg || data.message);
        if (navigateTo) navigate(navigateTo);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const handleChange = (id, data) => {
    setUserEmail(data.email);
    setSelectedUser(data);
  };

  const togggleActivity = (id, type) => {
    if (id === "header_All") {
      if (type === "add") {
        setAddActivity(allActivitiesWithSuffix);
        setRemoveActivity([]);
        setCheckedActivities([
          ...allActivities.map((item) => item.id + "_CRUDA"),
          "header_All",
          "header_Create",
          "header_Read",
          "header_Update",
          "header_Delete",
          "header_Archive",
        ]);
      } else if (type === "remove") {
        setRemoveActivity(allActivitiesWithSuffix);
        setAddActivity([]);
        setCheckedActivities([]);
      }
    } else if (id.startsWith("header")) {
      const suffix = id.split("_")[1].charAt(0);

      if (type === "add") {
        const newAddActivities = [
          ...addActivity,
          ...allActivitiesWithSuffix.filter((item) =>
            item.endsWith(`_${suffix}`)
          ),
        ];

        setAddActivity(newAddActivities);
        setRemoveActivity((prev) =>
          prev.filter((item) => !newAddActivities.includes(item))
        );
        setCheckedActivities((prev) => [...prev, id]);
      } else if (type === "remove") {
        const newRemoveActivities = [
          ...removeActivity,
          ...allActivitiesWithSuffix.filter((item) =>
            item.endsWith(`_${suffix}`)
          ),
        ];

        setRemoveActivity(newRemoveActivities);
        setAddActivity((prev) =>
          prev.filter((item) => !newRemoveActivities.includes(item))
        );
        setCheckedActivities((prev) => prev.filter((item) => item !== id));
      }
    } else if (id.endsWith("CRUDA")) {
      const parentId = id.substring(0, id.lastIndexOf("_"));

      if (type === "add") {
        const newAddActivities = [
          ...addActivity,
          ...allActivitiesWithSuffix.filter((item) =>
            item.startsWith(parentId)
          ),
        ];

        setAddActivity(newAddActivities);
        setRemoveActivity((prev) =>
          prev.filter((item) => !newAddActivities.includes(item))
        );
        setCheckedActivities((prev) => [...prev, id]);
      } else if (type === "remove") {
        const newRemoveActivities = [
          ...removeActivity,
          ...allActivitiesWithSuffix.filter((item) =>
            item.startsWith(parentId)
          ),
        ];

        setRemoveActivity(newRemoveActivities);
        setAddActivity((prev) =>
          prev.filter((item) => !newRemoveActivities.includes(item))
        );
        setCheckedActivities((prev) => prev.filter((item) => item !== id));
      }
    } else if (type === "add") {
      setAddActivity((prev) => [...prev, id]);
      setRemoveActivity((prev) => prev.filter((item) => item !== id));
    } else if (type === "remove") {
      setRemoveActivity((prev) => [...prev, id]);
      setAddActivity((prev) => prev.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    getAuthActivityGT();
  }, []);

  useEffect(() => {
    if (userEmail?.length >= 3 && !id) getUsers();
  }, [userEmail]);

  useEffect(() => {
    if (selectedUser.email) {
      setAddActivity([]);
      setRemoveActivity([]);
      setCheckedActivities(selectedUser.authorizedActivities);
    }
  }, [selectedUser]);

  useEffect(() => {
    setCheckedActivities((prev) => {
      const newCheckActivities = [...prev, ...addActivity];
      return newCheckActivities.filter(
        (item) => !removeActivity.includes(item)
      );
    });
  }, [addActivity, removeActivity]);

  useEffect(() => {
    if (id && edit) getUserFromId();
  }, [id, edit]);

  useEffect(() => {
    if (masterUser) {
      setSelectedUser({
        email: id,
        authorizedActivities: [],
      });
      setUserEmail(id);
    }
  }, [masterUser]);

  return (
    <div className="w-full">
      <Header Icon={securityIcon} title="Security Permissions" />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="w-full flex gap-x-2 justify-between">
          <div className={`flex gap-x-4 w-full`}>
            <div className="flex flex-col w-1/2">
              <Select
                iconClass="absolute top-[22%] left-2 text-secondary-gray"
                placeholder="Search by User Name or Email"
                inputFieldClass="placeholder:text-secondary-gray"
                outerClass=""
                dropdownData={users}
                id="security-permission-userEmail"
                fieldName="viewField"
                value={userEmail}
                onHandleInputChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                handleChange={handleChange}
                extraClass="relative w-full py-[0.1rem] rounded-full px-8 border-gray border-[1px]"
                disabled={id}
              />
              {!selectedUser.email && !isLoadingUpdate && !isLoading && (
                <span className="text-bold text-primary-red text-sm mt-1">
                  *Select A User To Manage Permissions.
                </span>
              )}
            </div>
          </div>

          <div className="min-w-fit">
            {masterUser ? (
              <Button
                iconClass=" w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add Permissions and Onboard User"
                onClick={addUserFromMaster}
                isLoading={isLoadingUpdate}
                disabled={
                  isLoadingUpdate ||
                  !selectedUser.email ||
                  selectedUser.authorizedActivities?.includes("*")
                }
                spinnerLight={true}
              />
            ) : (
              <Button
                iconClass=" w-[0.8rem] h-[0.8rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Update Permissions"
                onClick={() => updatePermissions()}
                isLoading={isLoadingUpdate}
                disabled={
                  isLoadingUpdate ||
                  !selectedUser.email ||
                  selectedUser.authorizedActivities?.includes("*")
                }
                spinnerLight={true}
              />
            )}
          </div>
        </div>
        {selectedUser.authorizedActivities?.includes("*") && (
          <div className="text-primary-red flex text-sm">
            <span>
              This user is a Company Administrator and therefore has all
              permissions by default. In order to change this user’s permissions
              you must first change the user role within their profile.
            </span>
          </div>
        )}

        <div className="overflow-auto flex-1 px-2">
          {!isLoading ? (
            <div className="flex flex-col w-full ">
              <Wrapper
                tableData={allActivities}
                authorizedActivities={selectedUser.authorizedActivities}
                checkedActivities={checkedActivities}
                onChange={togggleActivity}
                userSelected={selectedUser.email}
              />
            </div>
          ) : (
            <div className=" overflow-auto flex-1 px-2 min-h-[6rem] flex justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
