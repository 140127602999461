import { formatTimestamp } from "../../utils/helpers/dateFormat";
import Button from "../Button/Button";
import LabelValueField from "../LabelValueField/LabelValueField";

import DeleteIcon from "../../assets/svg/deleteIcon";
import { useState } from "react";
import axios from "../../utils/helpers/axios";
import toast from "react-hot-toast";

export default function ConcernHistoryCard({
  violation,
  getViolationHistory,
  showDelete = true,
  openedBy = true,
  closedBy = true,
}) {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const deleteViolation = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/violationHistory?openViolationId=${violation._id}`
      );

      if (data.success) {
        toast.success(data.message);
        getViolationHistory();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <div className="rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg p-[1.4rem] flex gap-[2rem] justify-between mx-[1rem]">
      <div className="flex flex-col gap-[0.4rem]">
        <div className="text-secondary-gray font-semibold">
          {violation?.violationData?.content}
        </div>
        <div className="flex gap-[2rem]">
          <div className="flex gap-[2rem]">
            <span className="flex text-primary-teal text-[0.9rem] font-semibold ">
              Opened :
            </span>
            <div className="flex gap-[1rem] text-primary-gray text-[0.9rem]">
              <span>{formatTimestamp(violation?.openedOn).split("\n")[0]}</span>
              <span>{formatTimestamp(violation?.openedOn).split("\n")[1]}</span>
            </div>
          </div>
          {openedBy && (
            <LabelValueField
              label="User"
              value={
                violation.openedByData.firstName +
                " " +
                violation.openedByData.lastName
              }
            />
          )}
        </div>

        <div className="flex gap-[2rem]">
          <div className="flex gap-[2.5rem]">
            <span className="text-primary-teal text-[0.9rem] font-semibold">
              Closed :
            </span>
            <div className="flex gap-[1rem] text-primary-gray text-[0.9rem] ">
              <span>{formatTimestamp(violation.closedOn).split("\n")[0]}</span>
              <span>{formatTimestamp(violation.closedOn).split("\n")[1]}</span>
            </div>
          </div>
          {closedBy && (
            <LabelValueField
              label="User"
              value={`${
                violation.closedByData
                  ? violation.closedByData.firstName +
                    " " +
                    violation.closedByData?.lastName
                  : ""
              }`}
            />
          )}
        </div>

        <LabelValueField
          label="Instances"
          value={violation.instanceFromJobViolation?.length}
        />
      </div>
      <div className="flex flex-col justify-between items-end">
        <div
          className={
            violation.status === "open"
              ? "text-primary-green"
              : "text-primary-gray"
          }
        >
          {violation.status.charAt(0).toUpperCase() + violation.status.slice(1)}
        </div>
        {showDelete && (
          <Button
            innerText={"Delete"}
            iconClass={"w-[15px] h-[15px]"}
            Icon={DeleteIcon}
            extraClass={`shadow-button bg-primary-white px-3 py-1 border-[1px] border-primary-red  rounded-full  text-[0.9rem] text-primary-red`}
            isLoading={isLoadingDelete}
            disabled={isLoadingDelete}
            onClick={deleteViolation}
          />
        )}
      </div>
    </div>
  );
}
